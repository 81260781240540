import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { authReducer } from "./auth";
import { departmentReducer } from "./department";
import { operatorReducer } from "./operators";
import { settingsReducer } from "./settings";
import { faqReducer } from "./faqs";
import { reportsReducer } from "./reports";
export default history =>
  combineReducers({
    router: connectRouter(history),
    authReducer,
    departmentReducer,
    operatorReducer,
    settingsReducer,
    faqReducer,
    reportsReducer
  });
