import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { messageStyles } from "./chatStyles";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import classNames from "classnames";
import TimeAgo from "react-timeago";
import { Icon } from "@material-ui/core";

class TimeFormat extends Component {
  render() {
    const { classes, incoming, createdAt } = this.props;
    const diff = moment().diff(new Date(createdAt), "milliseconds");
    const baseTime = 24 * 60 * 60 * 1000;
    return (
      <div
        className={classes.messageTime}
        style={{
          marginRight: !!incoming ? 0 : 25,
          marginLeft: !!incoming ? 25 : 0,
          justifyContent: !!incoming ? "flex-start" : "flex-end"
        }}
      >
        {diff < baseTime ? (
          <TimeAgo date={createdAt} formatter={formatTime} />
        ) : (
          moment(new Date(createdAt)).format("DD MMM YYYY h:mm a")
        )}
      </div>
    );
  }
}

TimeFormat.propTypes = {
  classes: PropTypes.object.isRequired
};

export const MyTimeFormat = withStyles(messageStyles)(TimeFormat);

class ChatImage extends Component {
  render() {
    const { classes, userId } = this.props;
    return (
      // <Avatar
      //   alt="Remy Sharp"
      //   src="/images/user.png"
      //   className={classes.avatar}
      //   style={{ alignSelf: "flex-start" }}
      // />
      <React.Fragment>
        {/* <img
            src={"/api/avatar/"+userId.id}
            className={classes.avatar}
            alt=""
          /> */}
        {userId.name === "Bot" ? (
          <img
            // src={`/api/logo/avatar/${userId._id}`}
            src={"/images/icon.png"}
            className={classes.avatar}
            alt=""
            style={{ backgroundColor: "gray" }}
          />
        ) : (
          <Icon style={{ fontSize: 35, color: "#546080" }}>account_circle</Icon>
        )}
      </React.Fragment>
    );
  }
}

class BotChatImage extends Component {
  render() {
    const { classes } = this.props;
    return (
      // <Avatar
      //   alt="Remy Sharp"
      //   src="/images/user.png"
      //   className={classes.avatar}
      //   style={{ alignSelf: "flex-start" }}
      // />

      <img src="/images/user_icon.png" className={classes.userAvatar} alt="" />
    );
  }
}
BotChatImage.prototypes = {
  classes: PropTypes.object.isRequired
};
ChatImage.propTypes = {
  classes: PropTypes.object.isRequired
};
export const UserChatImage = withStyles(messageStyles)(BotChatImage);
export const MyChatImage = withStyles(messageStyles)(ChatImage);

class ChipSet extends Component {
  render() {
    const {
      classes,
      content,
      incoming,
      onOptionSelect,
      roomStatus,
      closeModal,
      index: messageIndex,
      lastIndex
    } = this.props;

    return (
      <div
        className={classes.chipset}
        style={{ textAlign: !!incoming ? "left" : "right" }}
      >
        {content.options.map((option, index) => {
          return !!onOptionSelect && messageIndex == lastIndex - 1 ? (
            <Chip
              className={classNames(classes.chip, classes.chipFocus)}
              key={index}
              label={option.label}
              id={index}
              clickable={!!onOptionSelect}
              onClick={() => {
                !!onOptionSelect
                  ? onOptionSelect({
                      label: option.label,
                      response: option.id,
                      id: content.id
                    })
                  : console.log("hiii");
                closeModal ? closeModal() : console.log("hii");
              }}
            />
          ) : (
            <Chip
              className={classes.chip}
              key={index}
              label={option.label}
              id={index}
            />
          );
        })}
      </div>
    );
  }
}

ChipSet.propTypes = {
  classes: PropTypes.object.isRequired
};

export const MyChipSet = withStyles(messageStyles)(ChipSet);

export function formatTime(value, unit, suffix, epochSeconds, nextFormatter) {
  if (unit === "second") {
    return value < 15 ? "now" : "few seconds ago";
  }
  return nextFormatter();
}
