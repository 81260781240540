import React, { Component } from "react";
import "@material/react-layout-grid/dist/layout-grid.css";
import { Cell, Grid, Row } from "@material/react-layout-grid";
import { MyInputText } from "../formInputs/inputText";
import { connect } from "react-redux";
import { CardView } from "../formInputs/cardView";
import { signIn, logout } from "../../store/actions";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      token: ""
    };
  }

  async componentWillMount() {
    const token = this.props.location.search.replace("?token=", "");
    this.props.logout();
    if (token) {
      await this.login(token)();
    }
  }

  onChangeValue = label => e => {
    this.setState({ [label]: e.target.value });
  };

  keyPress = label => e => {
    if (e.key === "Enter") {
      // Do code here
      if (label === "username") {
        this.passwordRef.focus();
      } else {
        this.login("");
      }
      e.preventDefault();
    }
  };

  login = loginToken => event => {
    console.log(loginToken, "token");
    const { username, password } = this.state;
    const payload = {
      username,
      password,
      loginToken
    };
    this.props.signIn(payload);
  };

  render() {
    const { username, password } = this.state;
    return (
      <div
        style={{
          height: "100vh",
          backgroundImage: "linear-gradient(to top right, #0176BB,#03AADC)"
        }}
      >
        <Grid>
          <Row>
            <Cell columns={3} />
            <Cell columns={6}>
              <CardView
                heading="Login"
                buttonLabel="login"
                icon="Register"
                link="/register"
                submit={this.login("")}
              >
                <Row>
                  <Cell columns={2} />
                  <Cell columns={8}>
                    <form onSubmit={this.login("")}>
                      <p
                        style={{
                          fontSize: 15,
                          fontFamily: "OpenSans-Regular",
                          marginBottom: 0,
                          marginTop: 30
                        }}
                      >
                        User Name
                      </p>
                      <MyInputText
                        value={username}
                        onChange={this.onChangeValue("username")}
                        autoComplete="username"
                        onKeyPress={this.keyPress("username")}
                      />
                      <p
                        style={{
                          fontSize: 15,
                          fontFamily: "OpenSans-Regular",
                          marginBottom: 0,
                          marginTop: 30
                        }}
                      >
                        Password
                      </p>
                      <MyInputText
                        inputRef={ref => (this.passwordRef = ref)}
                        type="password"
                        value={password}
                        onChange={this.onChangeValue("password")}
                        onKeyPress={this.keyPress()}
                        autoComplete="current-password"
                      />
                    </form>
                  </Cell>
                  <Cell columns={2} />
                </Row>
              </CardView>
            </Cell>
            <Cell columns={3} />
          </Row>
        </Grid>
      </div>
    );
  }
}

export default connect(
  ({ authReducer: { token } }) => ({ token }),
  {
    signIn,
    logout
  }
)(Login);
