export const LoginStyles = theme => ({
  chatbot: {
    display: "flex",
    justifyContent: "center"
  }
});
export const chatStyles = theme => ({
  rootdiv: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    boxShadow: "1px 2px 3px 1px black",
    height: 50
  },
  chatbox: {
    display: "flex",
    width: "100%",
    maxHeight: "100vh",
    flexDirection: "column"
  },
  root: {
    display: "flex",
    flexGrow: 1,
    backgroundColor: "yellow"
  },
  bottomnav: {
    backgroundColor: "white",
    bottom: 0,
    padding: 0,
    minHeight: 0,
    position: "sticky",
    boxShadow: "0px 0px 0px 0px",
    borderTop: "1px solid lightgray",
    borderBottomWidth: 0
  },
  dialogsumbitbutton: {
    backgroundColor: "#0076bb",
    color: "white",
    fontSize: 12,
    marginLeft: 15,
    marginRight: 15,
    outline: 0,
    justifyContent: "center",
    paddingLeft: 25,
    paddingRight: 25
  },
  dialogbutton: {
    justifyContent: "flex-start",
    paddingLeft: 10,
    marginTop: 0,
    marginBottom: 24
  },
  conversation: {
    fontSize: 12,
    color: "#0176bb",
    marginTop: 10,
    fontFamily: "OpenSans-SemiBold"
  },
  toolbar: {
    minHeight: 0,
    paddingRight: 0
  },
  bottombarinner: {
    display: "flex",
    width: "100%"
  },
  input: {
    flexGrow: 1,
    padding: 0,
    minHeight: 0,
    paddingTop: 15,
    paddingBottom: 15,
    fontSize: 12
  },
  iconbox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    paddingLeft: 15,
    paddingRight: 15
  },
  topnav: {
    backgroundColor: "white",
    top: 0,
    position: "sticky",
    boxShadow: "0px 0px 0px 0px",
    borderBottom: "1px solid lightgray",
    borderTopWidth: 0
  },
  grow: {
    marginLeft: 10,
    color: "red"
  },
  messageBox: {
    flexGrow: 1,
    overflow: "scroll",
    maxHeight: "100vh",
    minHeight: "100vh",
    // marginTop: 5,
    padding: 10,
    backgroundColor: "#e6eaea"
    // paddingBottom: 150
    // borderLeft: "1px solid lightgray",
    // borderRight: "1px solid lightgray"
  },
  papericon: {
    color: "#3b3c42"
  },
  titled: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh"
  },
  topToolbar: {
    display: "flex",
    justifyContent: "space-between"
  },
  chatmiddle: {
    flexGrow: 1,
    position: "relative",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  chatroom: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column"
  },
  header: {
    borderBottom: "1px solid lightgray",
    backgroundColor: "#f3a61d",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 9,
    paddingLeft: 25,
    height: "55px",
    minHeight: "55px",
    alignItems: "Center"
  },
  chatbody: {
    position: "relative",
    flexGrow: 1,
    overflowY: "auto",
    backgroundColor: "white",
    paddingLeft: 10,
    paddingRight: 10
    // borderLeft: "1px solid lightgray"
    // boxShadow: "inset 2px -2px 13px -2px lightgrey"
  },
  chatContent: {
    minHeight: 0,
    position: "relative"
  },
  footer: {
    borderTop: "1px solid lightgray",
    backgroundColor: "#FFF"
  }
});

export const adminStyles = theme => ({
  root: {
    flexGrow: 1,
    overflow: "hidden"
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  chathead: {
    height: "100vh"
    // overflow: "Scroll"
  },
  icon: {
    backgroundColor: "grey",
    height: 40,
    width: 40,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  navbar: {
    position: "sticky",
    backgroundColor: "white",
    border: "1px solid grey",
    padding: 10
  },
  bottomnavbar: {
    display: "flex",
    position: "sticky",
    backgroundColor: "white",
    border: "0.3px solid white",
    padding: 5,
    // paddingRight:10,
    bottom: 0
  },
  chats: {
    // padding: 5,
    display: "flex",
    alignItems: "center"
  },
  middlegrid: {
    position: "relative",
    height: "100vh",
    borderLeft: "1px solid lightgray",
    borderRight: "1px solid lightgray"
    // boxShadow: "inset 2px -2px 13px -2px lightgrey"
  },
  lastgrid: {
    height: "100vh",
    overflow: "hidden",
    position: "relative"
    // boxShadow: "2px 11px 51px -14px lightgray"
  },
  // lasttitlediv: {
  //   margin: 20
  // },
  titlebox: {
    display: "flex",
    backgroundColor: "#eff6fa",
    padding: 20
  },
  guestitle: {
    fontSize: 20,
    fontFamily: "OpenSans-Bold"
  },
  totaloffline: {
    display: "flex",
    alignItems: "center",
    marginLeft: 10
  },
  offline: {
    fontSize: 15,
    fontFamily: "OpenSans-SemiBold",
    marginLeft: 2,
    color: "#f44a47"
  },
  online: {
    fontSize: 15,
    fontFamily: "OpenSans-SemiBold",
    marginLeft: 2,
    color: "#12bd48"
  },
  icondiv: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 5
  },
  icontext: {
    fontSize: 12,
    color: "#1c3f73",
    fontFamily: "OpenSans-Regular",
    marginLeft: 10
  },
  infoicon: {
    fontSize: 15,
    color: "#1c3f73"
  },
  lastbuttondiv: {
    margin: 20
  },
  button: {
    padding: 0,
    fontSize: 12,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 2,
    color: "#f44a47",
    backgroundColor: "transparent",
    border: "1px solid #f44a47",
    fontFamily: "OPenSans-Regular",
    marginLeft: 10,
    paddingLeft: 25,
    paddingRight: 30
  },
  buttonicon: {
    color: "#f44a47",
    fontSize: 12,
    marginRight: 5,
    marginBottom: 2
  },
  divider: {
    marginLeft: 15,
    marginRight: 15
  },
  customfields: {
    margin: 20,
    marginBottom: 10,
    fontSize: 12,
    fontFamily: "OpenSans-Bold",
    color: "#525457"
  },
  accesstokendiv: {
    display: "flex",
    marginLeft: 20,
    marginTop: 5
  },
  tokenlabel: {
    fontSize: 12,
    minWidth: "30%",
    color: "#828586",
    fontFamily: "OpenSans-SemiBold"
  },
  tokensublabel: {
    fontSize: 12,
    color: "#828586",
    fontFamily: "OpenSans-SemiBold"
  },
  pastchats: {
    margin: 20,
    marginBottom: 0,
    fontSize: 12,
    fontFamily: "OpenSans-Bold",
    color: "#525457",
    overflow: "scroll"
  },
  pastinner: {
    display: "flex",
    alignItems: "center",
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10
  },
  pastchatsDate: {
    marginLeft: 10,
    fontSize: 12,
    fontFamily: "OpenSans-SemiBold"
  },
  dot: {
    color: "#01b8ef"
  }
});

export const messageStyles = theme => ({
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 40 / 2
  },
  userAvatar: {
    width: 25,
    height: 25,
    marginTop: 7
  },
  messageBox: {
    marginTop: 15,
    marginBottom: 5
  },
  messageText: {
    // backgroundColor: "#fff",
    maxWidth: 300,
    padding: 2,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 12,
    paddingBottom: 12,
    marginTop: 5,
    fontFamily: "OpenSans-SemiBold",
    overflowWrap: "break-word",
    borderRadius: 20
  },
  media: {
    maxWidth: 300,
    padding: 10,
    borderRadius: 20
    // lineheight: 130%;
  },
  progressContainer: {
    height: 10,
    width: "100%",
    backgroundColor: "gray"
  },
  progress: {
    height: 10,
    backgroundColor: "#2c3e50"
  },
  messageTime: { display: "flex", fontSize: 8, color: "gray" },
  messageContent: {
    margin: 0,
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    paddingBottom: 2
  },
  statusContent: {
    backgroundColor: "#FFF",
    padding: 5,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 20,
    marginBottom: 10
  },
  chip: {
    backgroundColor: "transparent",
    border: "1px solid #4f1741",
    color: "#4f1741",
    margin: 2
  },
  chipFocus: {
    "&:hover": {
      backgroundColor: "white"
    },
    "&:focus": {
      backgroundColor: "white"
    }
  },
  chipset: {
    maxWidth: 300
  },
  chipContainer: {
    display: "flex",
    flexDirection: "column"
  },
  updatediv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: 20
  },
  updatecontent: {
    padding: 10,
    paddingLeft: 40,
    paddingRight: 40,
    border: "1px solid #e3f2f9",
    borderRadius: 30,
    color: "#2ebd84",
    marginRight: 15,
    fontSize: 15,
    fontFamily: "OpenSans-Bold",
    marginLeft: 15
  },
  updatedivider: {
    flexGrow: 1
  }
});
