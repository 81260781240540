import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import classNames from "classnames";
import { Cell, Row } from "@material/react-layout-grid";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { connect } from "react-redux";
import { getSettingsData, setBusinessHours } from "../../../store/actions";
import { BASE_URL } from "../../../store/api.middleware";

const styles = theme => ({
  settings: {
    fontSize: 20,
    fontFamily: "OpenSans-SemiBold",
    display: "flex",
    justifyContent: "center",
    marginTop: 50,
    marginBottom: 20
  },
  card: {
    marginLeft: 100,
    marginRight: 100,
    marginBottom: 50,
    paddingBottom: 30
  },
  label: {
    fontSize: 16,
    fontFamily: "OpenSans-SemiBold",
    color: "#0075bc",
    marginTop: 30
  },
  sublabel: {
    fontSize: 15,
    fontFamily: "OpenSans-Regular",
    color: "gray",
    marginTop: 5
  },
  textField: {
    width: "100%",
    marginTop: 15
  }
});

class Settings extends Component {
  constructor(props) {
    super(props);
    const {
      _id,
      welcome_message = "",
      start_time = "",
      idle_time = "",
      end_time = "",
      beyond_message = "",
      bot_name = "",
      logo = "",
      bot_logo = ""
    } = this.props.settings;
    this.state = {
      welcome_message,
      start_time,
      idle_time,
      end_time,
      beyond_message,
      bot_name,
      _id,
      logo,
      bot_logo
    };
    this.changeImage = this.changeImage.bind(this);
  }

  componentDidMount() {
    this.props.getSettingsData();
  }

  componentWillReceiveProps(nextProps) {
    const {
      _id,
      welcome_message = "",
      start_time = "",
      idle_time = "",
      end_time = "",
      beyond_message = "",
      bot_name = "",
      logo = "",
      bot_logo = ""
    } = nextProps.settings;
    this.setState({
      welcome_message,
      start_time,
      idle_time,
      end_time,
      beyond_message,
      bot_name,
      _id,
      logo,
      bot_logo
    });
  }

  changeImage = label => e => {
    if (!!e.target.files.length) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("type", label);
      fetch(BASE_URL + `/upload/upload_image?id=${this.state._id}`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${this.props.token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          this.setState({
            [label]: data.response[label]
          });
        });
    }
  };

  changeData = label => event => {
    this.setState({
      [label]: event.target.value
    });
  };

  sendData = label => event => {
    this.props.setBusinessHours({ [label]: this.state[label] }, this.state._id);
  };

  render() {
    const { classes } = this.props;
    const {
      welcome_message = "",
      start_time = "",
      idle_time = "",
      end_time = "",
      beyond_message = "",
      bot_name = "",
      logo = "",
      bot_logo = ""
    } = this.state;
    return (
      <div>
        <div>
          <div className={classes.settings}>SETTINGS</div>
        </div>
        <Card className={classes.card}>
          <CardContent>
            <Row>
              <Cell columns={3} />
              <Cell columns={6}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  <LogoComponent
                    title="Logo"
                    image={!!logo ? `http://chat.webileapps.com${logo}` : ""}
                    className={classes.label}
                    changeImage={this.changeImage}
                    id="logo"
                  />
                  <div style={{ width: 20 }} />
                  <LogoComponent
                    title="Bot Logo"
                    image={
                      !!bot_logo ? `http://chat.webileapps.com${bot_logo}` : ""
                    }
                    className={classes.label}
                    changeImage={this.changeImage}
                    id="bot_logo"
                  />
                </div>
                <div>
                  <div className={classes.label}>Start Time</div>
                  <div className={classes.sublabel}>
                    some text about the start time and description all about the
                    chat details and timings some text about the start time
                  </div>
                  <div>
                    <TextField
                      id="outlined-adornment-password"
                      className={classNames(classes.margin, classes.textField)}
                      variant="outlined"
                      inputProps={{
                        style: {
                          padding: 10
                        }
                      }}
                      type="time"
                      onBlur={this.sendData("start_time")}
                      onChange={this.changeData("start_time")}
                      value={start_time}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src="/images/time.png"
                              style={{ width: 20, height: 20 }}
                              alt=""
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </div>

                <div>
                  <div className={classes.label}>End Time</div>
                  <div className={classes.sublabel}>
                    some text about the start time and description all about the
                    chat details and timings some text about the start time
                  </div>
                  <div>
                    <TextField
                      id="outlined-adornment-password"
                      className={classNames(classes.margin, classes.textField)}
                      variant="outlined"
                      type="time"
                      onBlur={this.sendData("end_time")}
                      value={end_time}
                      inputProps={{
                        style: {
                          padding: 10
                        }
                      }}
                      onChange={this.changeData("end_time")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src="/images/time.png"
                              style={{ width: 20, height: 20 }}
                              alt=""
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div className={classes.label}>Welcome Message</div>
                  <div className={classes.sublabel}>
                    some text about the start time and description all about the
                    chat details and timings some text about the start time
                  </div>
                  <div>
                    <TextField
                      id="outlined-adornment-password"
                      multiline
                      rowsMax="4"
                      value={welcome_message}
                      className={classNames(classes.margin, classes.textField)}
                      onChange={this.changeData("welcome_message")}
                      onBlur={this.sendData("welcome_message")}
                      variant="outlined"
                      inputProps={{
                        style: {
                          padding: 20,
                          paddingLeft: 10,
                          paddingRight: 10
                        }
                      }}
                    />
                  </div>
                </div>

                <div>
                  <div className={classes.label}>Beyond Message</div>
                  <div className={classes.sublabel}>
                    some text about the start time and description all about the
                    chat details and timings some text about the start time
                  </div>
                  <div>
                    <TextField
                      id="outlined-adornment-password"
                      className={classNames(classes.margin, classes.textField)}
                      variant="outlined"
                      multiline
                      rowsMax="4"
                      onChange={this.changeData("beyond_message")}
                      onBlur={this.sendData("beyond_message")}
                      value={beyond_message}
                      inputProps={{
                        style: {
                          padding: 20,
                          paddingLeft: 10,
                          paddingRight: 10
                        }
                      }}
                    />
                  </div>
                </div>

                <div>
                  <div className={classes.label}>Bot Name</div>
                  <div className={classes.sublabel}>
                    some text about the start time and description all about the
                    chat details and timings some text about the start time
                  </div>
                  <div>
                    <TextField
                      id="outlined-adornment-password"
                      className={classNames(classes.margin, classes.textField)}
                      variant="outlined"
                      onChange={this.changeData("bot_name")}
                      onBlur={this.sendData("bot_name")}
                      value={bot_name}
                      inputProps={{
                        style: {
                          padding: 10
                        }
                      }}
                    />
                  </div>
                </div>

                <div>
                  <div className={classes.label}>idle Time</div>
                  <div className={classes.sublabel}>
                    some text about the start time and description all about the
                    chat details and timings some text about the start time
                  </div>
                  <div>
                    <TextField
                      id="outlined-adornment-password"
                      className={classNames(classes.margin, classes.textField)}
                      variant="outlined"
                      onChange={this.changeData("idle_time")}
                      onBlur={this.sendData("idle_time")}
                      inputProps={{
                        style: {
                          padding: 10
                        }
                      }}
                      type="time"
                      onBlur={event => console.log(event.target.value)}
                      value={idle_time}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src="/images/time.png"
                              style={{ width: 20, height: 20 }}
                              alt=""
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </div>
              </Cell>
              <Cell columns={3} />
            </Row>
          </CardContent>
        </Card>
      </div>
    );
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(
  connect(
    ({ settingsReducer, authReducer }) => {
      const settings = settingsReducer;
      const { token } = authReducer;
      return { settings, token };
    },
    { getSettingsData, setBusinessHours }
  )(Settings)
);

function LogoComponent(props) {
  const { title, image, className, changeImage, id } = props;
  return (
    <div>
      <div className={className}>{title}</div>
      <div style={{ marginTop: 10 }}>
        <label htmlFor={id}>
          {!!image ? (
            <img
              src={image}
              style={{
                width: 80,
                height: 80,
                borderRadius: 80 / 2
              }}
              alt="logo"
            />
          ) : (
            <div
              style={{
                width: 80,
                height: 80,
                borderRadius: 80 / 2,
                backgroundColor: "gray"
              }}
            />
          )}
        </label>

        <input
          type="file"
          id={id}
          name={title}
          accept="image/*"
          style={{ display: "none" }}
          onChange={changeImage(id)}
        />
      </div>
    </div>
  );
}
