import { CALL_API } from "../api.middleware";
import {
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILED,
  USER_LOGOUT_SUCCESS,
  CHANGE_STATUS,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_FAILED,
  SET_TOKEN,
  SET_TOKEN_SUCCESS,
  SET_TOKEN_FAILURE
} from "../types";
import { replace } from "connected-react-router";

export const signIn = payload => async dispatch => {
  try {
    await dispatch({
      [CALL_API]: {
        url: `/users/login`,
        types: [USER_LOGIN, USER_LOGIN_SUCCESS, USER_LOGIN_FAILED],
        body: payload,
        method: "POST",
        params: { token: payload.loginToken }
      }
    });
    dispatch(replace("/dashboard"));
  } catch (error) {
    alert(error);
  }
};

export const changeStatus = payload => async dispatch => {
  try {
    await dispatch({
      [CALL_API]: {
        url: `/users/set_status`,
        types: [CHANGE_STATUS, CHANGE_STATUS_SUCCESS, CHANGE_STATUS_FAILED],
        body: payload,
        method: "POST"
      }
    });
  } catch (error) {
    alert(error);
  }
};

export const logout = () => {
  return {
    type: USER_LOGOUT_SUCCESS
  };
};
