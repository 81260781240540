import React, { Component } from "react";
import { MyInputText } from "../../formInputs/inputText";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import {
  getDepartmentsList,
  getCategories,
  createCategory,
  createFaq,
  editFaq,
  getFaqDetail
} from "../../../store/actions";
import MenuItem from "@material-ui/core/MenuItem";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import TextEditor from "./editor";
import { MyDialog } from "../departments/list";
import { BASE_URL } from "../../../store/api.middleware";

class CreateFaq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqTitle: "",
      department: "all",
      newCategory: "",
      openDialog: false,
      editorState: EditorState.createEmpty(),
      selectedCategory:
        this.props.categories.length > 0 ? this.props.categories[0]._id : ""
    };
  }

  async componentDidMount() {
    const { params } = this.props.match || {};
    this.props.getDepartmentsList();
    this.props.getCategories();
    if (!!params && !!params.faqId) {
      const response = await this.props.getFaqDetail(params.faqId);
      const { articleDetail } = response;
      const {
        article_title,
        department,
        article_content,
        category
      } = articleDetail;
      this.setState({
        faqTitle: article_title,
        department: !!department ? department._id : "all",
        editorState: EditorState.createWithContent(
          convertFromRaw(JSON.parse(article_content))
        ),
        selectedCategory: category._id
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { categories = [] } = this.props;
    if (categories.length !== prevProps.categories.length) {
      this.setState({
        selectedCategory: categories[0]._id
      });
    }
  }

  onChangeValue = label => e => {
    this.setState({ [label]: e.target.value });
  };

  onEditorStateChange = editorState => {
    this.setState({ editorState });
  };

  closeModal = () => {
    this.setState({
      newCategory: "",
      openDialog: false
    });
  };

  uploadImageCallBack = file => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", "faq");
    return fetch(BASE_URL + `/upload/upload_image`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${this.props.token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        return { data: { link: `http://chat.webileapps.com${data.response}` } };
      });
  };

  createCategory = () => {
    const payload = {
      name: this.state.newCategory
    };
    this.props.createCategory(payload);
    this.setState({
      newCategory: "",
      openDialog: false
    });
  };

  saveFaq = async () => {
    const { params } = this.props.match || {};
    const payload = {
      department:
        this.state.department === "all" ? null : this.state.department,
      category: this.state.selectedCategory,
      article_title: this.state.faqTitle,
      article_content: JSON.stringify(
        convertToRaw(this.state.editorState.getCurrentContent())
      )
    };
    !!params && !!params.faqId
      ? await this.props.editFaq(payload, params.faqId)
      : await this.props.createFaq(payload);
    this.props.history.goBack();
  };

  render() {
    const { departments = [], categories = [] } = this.props;
    const { faqTitle, department, selectedCategory } = this.state;
    return (
      <div style={{ padding: 20 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ flexGrow: 2 }}>
            <div style={{ fontSize: 15, fontFamily: "OpenSans-Regular" }}>
              FAQ Title
            </div>
            <MyInputText
              value={faqTitle}
              onChange={this.onChangeValue("faqTitle")}
            />
          </div>
          <div style={{ width: 20 }} />
          <div style={{ flexGrow: 1 }}>
            <div style={{ fontSize: 15, fontFamily: "OpenSans-Regular" }}>
              Select Department
            </div>
            <MyInputText
              select
              value={department}
              onChange={this.onChangeValue("department")}
            >
              <MenuItem key="all" value="all">
                All Departments
              </MenuItem>
              {departments.map(department => (
                <MenuItem key={department._id} value={department._id}>
                  {department.name}
                </MenuItem>
              ))}
            </MyInputText>
          </div>
          <div style={{ width: 20 }} />
          <div style={{ flexGrow: 1 }}>
            <div style={{ display: "flex" }}>
              <div style={{ fontSize: 15, fontFamily: "OpenSans-Regular" }}>
                Select Category
              </div>
              <div style={{ width: 10 }} />
              <div
                onClick={() => this.setState({ openDialog: true })}
                style={{ cursor: "pointer", color: "#0075bc" }}
              >
                Create Category
              </div>
            </div>
            <MyInputText
              select
              disabled={!selectedCategory}
              value={selectedCategory}
              onChange={this.onChangeValue("selectedCategory")}
            >
              {categories.map(category => (
                <MenuItem key={category._id} value={category._id}>
                  {category.name}
                </MenuItem>
              ))}
            </MyInputText>
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <div
            style={{
              fontSize: 15,
              fontFamily: "OpenSans-Regular",
              marginBottom: 10
            }}
          >
            Type your answer here
          </div>
          <TextEditor
            editorState={this.state.editorState}
            onEditorStateChange={this.onEditorStateChange}
            uploadImageCallBack={this.uploadImageCallBack}
          />
        </div>
        <div style={{ display: "flex", marginTop: 10 }}>
          <Button
            onClick={this.saveFaq}
            variant="outlined"
            disabled={!department || !faqTitle || !selectedCategory}
          >
            Save
          </Button>
          <div style={{ width: 20 }} />
          <Button
            onClick={() => this.props.history.goBack()}
            variant="outlined"
          >
            Cancel
          </Button>
        </div>
        <MyDialog
          open={this.state.openDialog}
          closeModal={this.closeModal}
          submitModal={this.createCategory}
          title="New Category"
          submitButton="Save"
          cancelButton="Cancel"
          content={
            <MyInputText
              label="Category Name"
              value={this.state.newCategory}
              onChange={this.onChangeValue("newCategory")}
            />
          }
        />
      </div>
    );
  }
}

export default connect(
  ({ departmentReducer, faqReducer, authReducer }) => {
    const { departments = [] } = departmentReducer;
    const { token } = authReducer;
    const { categories = [], articleDetail } = faqReducer;
    return { departments, categories, articleDetail, token };
  },
  {
    getDepartmentsList,
    getCategories,
    getFaqDetail,
    createCategory,
    createFaq,
    editFaq
  }
)(CreateFaq);
