import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { loadCSS } from "fg-loadcss/src/loadCSS";
import InputBase from "@material-ui/core/InputBase";
import Message from "./message";
import * as SocketIOFileUpload from "socketio-file-upload";
import LocalFile from "./localFile";
import { debounce } from "debounce";
import { chatStyles } from "./chatStyles";
import { TYPING, MESSAGE_ENTERED, INPUT } from "../../../store/types/socket";
import StarRating from "../../../starRating";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import classNames from "classnames";
import Input from "@material-ui/core/Input";
class CommonChat extends Component {
  constructor(props) {
    super(props);
    this.siofu = new SocketIOFileUpload(this.props.socket);
    this.state = {
      value: "",
      files: [],
      typeStatus: "",
      agentRating: 0,
      queryRating: 0,
      comments: "",
      open: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.typingMessage = debounce(this.typingMessage, 2000, true);
    this.messageEntered = debounce(this.messageEntered, 3000);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.handleAttachment = this.handleAttachment.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.onMessagesRefChanged = this.onMessagesRefChanged.bind(this);
    this.messagePanel = null;
    this.progress = this.progress.bind(this);
    this.complete = this.complete.bind(this);
    this.start = this.start.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value, typeStatus: TYPING });
    if (this.state.typeStatus !== TYPING) {
      this.typingMessage();
    }
    this.messageEntered.clear();
    this.messageEntered();
  }

  typingMessage() {
    this.props.onChange(TYPING);
  }

  messageEntered() {
    this.setState({ typeStatus: MESSAGE_ENTERED });
    this.props.onChange(MESSAGE_ENTERED);
  }

  handleSubmit(event, message) {
    this.typingMessage.clear();
    this.messageEntered.clear();
    this.props.onChange(MESSAGE_ENTERED);
    if (message.type === INPUT && this.props.roomStatus !== 5) {
      const { id } = JSON.parse(message.content);
      this.props.onOptionSelect({ response: this.state.value, id });
    } else if (!!this.state.value.trim()) {
      this.props.submitMessage(this.state.value);
    }
    this.setState({ value: "", typeStatus: "" });
    event.preventDefault();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.messages.length !== this.props.messages.length ||
      (prevProps.typing !== TYPING && this.props.typing === TYPING) ||
      !!this.props.scroll ||
      !!this.props.time
    ) {
      this.scrollToBottom();
    }

    if (
      this.props.roomStatus === 3 &&
      !this.props.userRated &&
      this.state.open === false
    ) {
      this.setState({ open: true });
    }
    if (
      this.props.roomStatus === 3 &&
      !!this.props.userRated &&
      this.state.open === true
    ) {
      this.setState({ open: false });
    }
  }

  scrollToBottom() {
    setTimeout(_ => {
      if (!!this.messagePanel) {
        this.messagePanel.scrollTop =
          this.messagePanel.scrollHeight - this.messagePanel.clientHeight;
      }
    }, 10);
  }

  keyPress = (e, message) => {
    if (!e.shiftKey && e.key === "Enter") {
      // Do code here
      this.handleSubmit(e, message);
      e.preventDefault();
    }
  };

  handleAttachment(event) {
    event.preventDefault();
    this.state.siofu.prompt();
    return false;
  }

  onMessagesRefChanged(element) {
    this.messagePanel = element;
    if (!element) {
      return;
    }
    this.scrollToBottom();
    // const siofu = new SocketIOFileUpload(this.props.socket);
    this.siofu.listenOnDrop(this.messagePanel);
    this.siofu.addEventListener("progress", this.progress);

    this.siofu.addEventListener("start", this.start);

    this.siofu.addEventListener("complete", this.complete);

    this.setState({ siofu: this.siofu });
  }

  progress(event) {
    var progress = (event.bytesLoaded / event.file.size) * 100;
    this.setState(({ files = [] }) => {
      return {
        files: files.map(({ file, percent }) => ({
          file,
          percent: file.name === event.file.name ? progress : percent
        }))
      };
    });
  }

  start(event) {
    this.setState(({ files = [] }) => ({
      files: files.concat({ file: event.file, percent: 0 })
    }));
    this.scrollToBottom();
  }

  complete(event) {
    this.setState({ files: [] });
    this.scrollToBottom();
  }

  componentWillUnmount() {
    this.siofu.removeEventListener("progress", this.progress);
    this.siofu.removeEventListener("start", this.start);
    this.siofu.removeEventListener("complete", this.complete);
  }

  componentDidMount() {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.1.0/css/all.css",
      document.querySelector("#insertion-point-jss")
    );
  }

  handleModalClose = () => {
    this.setState({
      // open: false,
      agentRating: 0,
      queryRating: 0,
      comments: ""
    });
  };

  render() {
    const { files = [], agentRating, queryRating, comments } = this.state;
    const {
      classes,
      roomStatus,
      user,
      role,
      selectedAgent,
      messages,
      userChat,
      imageUrl = "/images/chat.png",
      typing,
      onOptionSelect,
      startNewConversataion,
      continueConversation,
      agentName,
      time
    } = this.props;
    console.log(messages);
    return (
      <React.Fragment>
        <div className={classes.chatbody} ref={this.onMessagesRefChanged}>
          <div className={classes.chatContent}>
            {messages.map((message, index, array) => (
              <Message
                user={user}
                role={role}
                key={message._id}
                imageUrl={imageUrl}
                message={message}
                userChat={userChat}
                onOptionSelect={onOptionSelect}
                roomStatus={roomStatus}
                index={index}
                lastIndex={array.length}
              />
            ))}

            {files.map(({ file, percent }) => (
              <LocalFile
                key={file.name}
                percent={percent}
                file={file}
                onImageLoaded={this.scrollToBottom}
              />
            ))}

            {!!time && (
              <div>
                Conversation will end in {time} seconds. Please respond if you
                want to continue the conversation.
              </div>
            )}

            {(roomStatus === 4 ||
              (roomStatus === 3 && !!this.props.userRated)) &&
              !!userChat && (
                <div
                  onClick={continueConversation}
                  style={{ cursor: "pointer" }}
                >
                  Your chat was ended. Want to continue?
                </div>
              )}

            {roomStatus === 3 && !!userChat && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Dialog
                  open={this.state.open}
                  onClose={() => this.props.onCloseModal()}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent style={{ paddingLeft: 20, paddingRight: 20 }}>
                    {agentName === "Bot" ? (
                      <Fragment>
                        <div>Please rate your experience</div>
                        <StarRating
                          onSubmit={val => this.setState({ agentRating: val })}
                          value={0}
                          color="gold"
                        />
                        <div className={classes.conversation}>
                          Please leave your comments here (Optional).
                        </div>
                        <Input
                          onChange={event =>
                            this.setState({ comments: event.target.value })
                          }
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className={classes.conversation}>
                          How was your conversation with {agentName} ?
                        </div>
                        <StarRating
                          onSubmit={val => this.setState({ agentRating: val })}
                          value={0}
                          color="gold"
                        />
                        <div className={classes.conversation}>
                          Were we able to resolve your query ?
                        </div>
                        <StarRating
                          onSubmit={val => this.setState({ queryRating: val })}
                          value={0}
                          color="gold"
                        />
                        <div className={classes.conversation}>
                          Please leave your comments here (Optional).
                        </div>
                        <Input
                          onChange={event =>
                            this.setState({ comments: event.target.value })
                          }
                        />
                      </Fragment>
                    )}
                  </DialogContent>
                  <DialogActions className={classes.dialogbutton}>
                    <Button
                      onClick={() => {
                        this.props.submitUserFeedBack({
                          agentRating,
                          queryRating,
                          comments
                        });
                        this.handleModalClose();
                      }}
                      className={classNames(
                        classes.dialogsumbitbutton,
                        "button-outline"
                      )}
                      // autoFocus
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
                {/* <div
                  style={{
                    backgroundColor: "#FFF",
                    display: "flex",
                    justifyContent: "Center",
                    marginBottom: 10,
                    flexDirection: "column",
                    alignItems: "center",
                    borderRadius: 5,
                    padding: 10
                  }}
                >
                  <div>Rate your experience</div>
                  <StarRating
                    onSubmit={this.props.submitRating}
                    value={0}
                    color="gray"
                  />
                </div> */}
              </div>
            )}

            {typing === TYPING && (
              <div
                className="typing-indicator"
                style={{ backgroundColor: "#E6EAEA" }}
              >
                <span />
                <span />
                <span />
              </div>
            )}
          </div>
        </div>
        {(roomStatus === 4 || (roomStatus === 3 && !!this.props.userRated)) &&
          !!userChat && (
            <Button
              className="button-style"
              variant="outlined"
              onClick={startNewConversataion}
              style={{
                backgroundColor: "#f3a61d",
                color: "white",
                minHeight: 40
                // marginBottom: !!this.props.bottom ? this.props.bottom : 0
              }}
            >
              Start new conversation
            </Button>
          )}
        {((!!selectedAgent && selectedAgent._id === user) || !!userChat) &&
          !(roomStatus > 2 && roomStatus < 5) && (
            <div
              className={classes.footer}
              // style={{
              //   marginBottom: !!this.props.bottom ? this.props.bottom : 0
              // }}
            >
              <form
                onSubmit={event =>
                  this.handleSubmit(event, messages[messages.length - 1])
                }
                className={classes.bottombarinner}
              >
                <InputBase
                  multiline
                  rowsMax="4"
                  autoFocus={true}
                  value={this.state.value}
                  onChange={this.handleChange}
                  className={classes.input}
                  placeholder="Type a message....."
                  onKeyPress={event =>
                    this.keyPress(event, messages[messages.length - 1])
                  }
                  style={{ padding: 10, paddingLeft: 25, minHeight: 45 }}
                />
                <div
                  className={classes.iconbox}
                  onClick={this.handleAttachment}
                >
                  <img
                    src="/images/attach_icon.png"
                    style={{ height: 20, width: 20 }}
                    alt=""
                  />
                </div>
                <div
                  className={classes.iconbox}
                  style={{ color: "#FFF" }}
                  onClick={event =>
                    this.handleSubmit(event, messages[messages.length - 1])
                  }
                >
                  {!!userChat && (
                    <img
                      src="/images/close_button.png"
                      style={{
                        height: 30,
                        width: 30,
                        marginRight: 10,
                        cursor: "pointer"
                      }}
                      onClick={this.props.handleModalOpen}
                      // onClick={() => console.log("hi")}
                    />
                  )}
                  <img
                    src="/images/send_icon.png"
                    style={{ height: 20, width: 20 }}
                    alt=""
                  />
                </div>
              </form>
            </div>
          )}
      </React.Fragment>
    );
  }
}

// if (roomStatus == 3 && this.state.open == false && agentRating == 0) {
// }

CommonChat.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(chatStyles)(CommonChat);

/**
                 handle submit 

                 add in backend
               */
