import React, { Component } from "react";
import { MyInputText } from "../../formInputs/inputText";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { ADMIN, AGENT } from "../../../store/types";
import { connect } from "react-redux";
import {
  createOperator,
  getOperator,
  updateOperator
} from "../../../store/actions";
class CreateOperator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      name: "",
      password: "",
      role: AGENT
    };
  }

  onChangeValue = label => e => {
    this.setState({ [label]: e.target.value });
  };

  async componentDidMount() {
    const { params } = this.props.match || {};
    if (!!params && params.operatorId) {
      const response = await this.props.getOperator(params.operatorId);
      const { details } = response;
      const { username, name, role } = details;
      this.setState({
        userName: username,
        name,
        role
      });
    }
  }

  submit = async () => {
    const { params } = this.props.match || {};
    const { userName, name, password, role } = this.state;
    let payload = {
      username: userName,
      name,
      role
    };
    payload =
      !!params && !!params.operatorId
        ? { ...payload, password }
        : !!password
        ? { ...payload, password }
        : payload;
    const response =
      !!params && !!params.operatorId
        ? await this.props.updateOperator(payload, params.operatorId)
        : await this.props.createOperator(payload);
    if (!!response) {
      this.props.history.goBack();
    }
  };

  render() {
    return (
      <div>
        <MyInputText
          disabled={
            !!this.props.match.params && !!this.props.match.params.operatorId
          }
          label="Email"
          value={this.state.userName}
          onChange={this.onChangeValue("userName")}
        />

        <MyInputText
          label="Name"
          value={this.state.name}
          onChange={this.onChangeValue("name")}
        />

        <MyInputText
          type="password"
          label="Password"
          value={this.state.password}
          onChange={this.onChangeValue("password")}
        />

        <MyInputText
          select
          label="Role"
          value={this.state.role}
          onChange={this.onChangeValue("role")}
        >
          <MenuItem key={ADMIN} value={ADMIN}>
            Admin
          </MenuItem>
          <MenuItem key={AGENT} value={AGENT}>
            Agent
          </MenuItem>
        </MyInputText>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 10,
            marginBottom: 10
          }}
        >
          <Button
            className="button-style"
            variant="outlined"
            onClick={this.submit}
          >
            {!!this.props.match.params && !!this.props.match.params.operatorId
              ? "Update"
              : "Create"}
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { createOperator, getOperator, updateOperator }
)(CreateOperator);
