import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AppRouter from "./appRouter";
import { connect } from "react-redux";
import { logout, changeStatus } from "../../store/actions";
import { ADMIN, ONLINE, OFFLINE, BUSY, AWAY, AGENT } from "../../store/types";
import Icon from "@material-ui/core/Icon";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 9 + 1
    }
  },
  outline: {
    "&:focus": {
      outline: 0
    },
    color: "white",
    background: "#516d95",
    padding: 5,
    marginTop: 10,
    marginRight: 8
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px"
    // ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4
  },
  paper: {
    backgroundColor: "#1d3f73"
  },
  ListItem: {
    paddingTop: 20
  },
  listicon: {
    height: 20,
    width: 20,
    marginLeft: 10,
    color: "white",
    fontSize: 20
  },

  listtext: {
    color: "white",
    marginBottom: 0,
    fontSize: 15,
    fontFamily: "OpenSans-SemiBold"
  }
});

class MiniDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openMenu: false
    };
  }

  handleClick = () => {
    this.setState(state => ({ openMenu: !state.openMenu }));
  };

  componentWillMount() {
    const { role } = this.props;
    // const { role } = JSON.parse(atob(token.split(".")[1]));
    this.setState({ role });
  }

  handleDrawer = () => {
    this.setState({ open: !this.state.open });
  };

  changeStatus = status => async () => {
    await this.props.changeStatus({ status });
    this.handleClick();
  };

  logout = () => {
    this.props.logout();
    // this.props.history.go(-1);
    this.props.history.replace("/");
  };

  render() {
    const { classes, match, status } = this.props;
    const { url } = match;
    const statusList = [
      { status: ONLINE, color: "#12bd48", backgroundColor: "#baebc7" },
      { status: OFFLINE, color: "#fe0000", backgroundColor: "#ffb7b7" },
      { status: AWAY, color: "#c3c1c1", backgroundColor: "#feffd5" },
      { status: BUSY, color: "#ebca06", backgroundColor: "#d0d0d0" }
    ];

    const color = statusList.find(datum => datum.status === status).color;
    const bgColor = statusList.find(datum => datum.status === status)
      .backgroundColor;

    return (
      <div className={classes.root}>
        <CssBaseline />

        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open
          })}
          classes={{
            paper: classNames(classes.paper, {
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open
            })
          }}
          open={this.state.open}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={this.handleDrawer} className={classes.outline}>
              {this.state.open ? <ChevronLeftIcon /> : <MenuIcon />}
            </IconButton>
          </div>

          <List>
            <ListItem className={classes.ListItem}>
              <ListItemIcon>
                <Icon className={classes.listicon}>account_circle</Icon>
              </ListItemIcon>
              <ListItemText>
                <p className={classes.listtext}>{this.props.name}</p>
              </ListItemText>
            </ListItem>
          </List>

          {this.state.role === ADMIN && (
            <List>
              <ListItem
                button
                onClick={() => this.props.history.push(`${url}/home`)}
                className={classes.ListItem}
              >
                <ListItemIcon className={classes.item}>
                  <img
                    src="/images/dashboard_icon.png"
                    className={classes.listicon}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText>
                  <p className={classes.listtext}>Dashboard</p>
                </ListItemText>
              </ListItem>

              <ListItem
                button
                onClick={() => this.props.history.push(`${url}/departments`)}
                className={classes.ListItem}
              >
                <ListItemIcon>
                  <img
                    src="/images/departments_icon.png"
                    className={classes.listicon}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText>
                  <p className={classes.listtext}>Department</p>
                </ListItemText>
              </ListItem>
              <ListItem
                button
                onClick={() => this.props.history.push(`${url}/operators`)}
                className={classes.ListItem}
              >
                <ListItemIcon>
                  <img
                    src="/images/operators_icons.png"
                    className={classes.listicon}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText>
                  <p className={classes.listtext}>Operators</p>
                </ListItemText>
              </ListItem>
              <ListItem
                button
                onClick={() => this.props.history.push(`${url}/settings`)}
              >
                <ListItemIcon>
                  <img
                    src="/images/settings_icon.png"
                    className={classes.listicon}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText>
                  <p className={classes.listtext}>Settings</p>
                </ListItemText>
              </ListItem>
              <ListItem
                button
                onClick={() => this.props.history.push(`${url}/faqs`)}
                className={classes.ListItem}
              >
                <ListItemIcon>
                  <img
                    src="/images/chat_icon.png"
                    className={classes.listicon}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText>
                  <p className={classes.listtext}>Faqs</p>
                </ListItemText>
              </ListItem>
            </List>
          )}
          <List>
            <ListItem
              button
              onClick={() => this.props.history.push(`${url}/conversations`)}
              className={classes.ListItem}
            >
              <ListItemIcon>
                <img
                  src="/images/chat_icon.png"
                  className={classes.listicon}
                  alt=""
                />
              </ListItemIcon>
              <ListItemText>
                <p className={classes.listtext}>Chat</p>
              </ListItemText>
            </ListItem>

            {this.state.role === AGENT && (
              <ListItem button onClick={this.handleClick}>
                <ListItemIcon
                  style={{
                    padding: 2,
                    backgroundColor: bgColor,
                    marginLeft: 7,
                    borderRadius: 20
                  }}
                >
                  <Icon style={{ color: color, fontSize: 18 }}>
                    fiber_manual_record
                  </Icon>
                </ListItemIcon>
                <ListItemText>
                  <p className={classes.listtext}>{status}</p>
                </ListItemText>
                {this.state.openMenu ? (
                  <ExpandLess style={{ color: "#FFF" }} />
                ) : (
                  <ExpandMore style={{ color: "#FFF" }} />
                )}
              </ListItem>
            )}
            {this.state.role === AGENT && (
              <Collapse in={this.state.openMenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {statusList.map((datum, i) => (
                    <ListItem
                      button
                      className={classes.nested}
                      onClick={this.changeStatus(datum.status)}
                      key={i}
                    >
                      <ListItemIcon
                        style={{
                          backgroundColor: datum.backgroundColor,
                          borderRadius: 20
                        }}
                      >
                        <Icon style={{ color: datum.color, fontSize: 18 }}>
                          fiber_manual_record
                        </Icon>
                      </ListItemIcon>
                      <ListItemText>
                        <p className={classes.listtext}>{datum.status}</p>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
            <ListItem button onClick={this.logout} className={classes.ListItem}>
              <ListItemIcon>
                <img
                  src="/images/logout_icon.png"
                  className={classes.listicon}
                  alt=""
                />
              </ListItemIcon>
              <ListItemText>
                <p className={classes.listtext}>Logout</p>
              </ListItemText>
            </ListItem>
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <AppRouter {...this.props} role={this.state.role} />
        </main>
      </div>
    );
  }
}

const MyMiniDrawer = connect(
  ({ authReducer }) => {
    const { token, status, role, name } = authReducer;
    return { token, status, role, name };
  },
  { logout, changeStatus }
)(MiniDrawer);

MyMiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(MyMiniDrawer);
