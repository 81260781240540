import { CALL_API } from "../api.middleware";
import {
  GET_DEPARTMENTS_LIST,
  GET_DEPARTMENTS_LIST_SUCCESS,
  GET_DEPARTMENTS_LIST_FAILED,
  CREATE_DEPARTMENT,
  CREATE_DEPARTMENT_SUCCESS,
  CREATE_DEPARTMENT_FAILED,
  UPDATE_DEPARTMENT,
  UPDATE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_FAILED,
  DELETE_DEPARTMENT,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_FAILED
} from "../types/department";

export const getDepartmentsList = () => async dispatch => {
  try {
    await dispatch({
      [CALL_API]: {
        url: "/department",
        method: "GET",
        types: [
          GET_DEPARTMENTS_LIST,
          GET_DEPARTMENTS_LIST_SUCCESS,
          GET_DEPARTMENTS_LIST_FAILED
        ]
      }
    });
  } catch (error) {
    alert(error);
  }
};

export const createDepartment = payload => async dispatch => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: "/department/create",
        method: "POST",
        types: [
          CREATE_DEPARTMENT,
          CREATE_DEPARTMENT_SUCCESS,
          CREATE_DEPARTMENT_FAILED
        ],
        body: payload
      }
    });
  } catch (error) {
    alert(error);
  }
};

export const getDepartmentDetail = id => async dispatch => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/department/${id}/get-dept-detail`,
        method: "GET"
      }
    });
  } catch (error) {
    alert(error);
  }
};

export const editDepartment = (payload, id) => async dispatch => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: "/department/update",
        method: "POST",
        types: [
          UPDATE_DEPARTMENT,
          UPDATE_DEPARTMENT_SUCCESS,
          UPDATE_DEPARTMENT_FAILED
        ],
        body: payload,
        params: { departmentId: id }
      }
    });
  } catch (error) {
    alert(error.message);
  }
};

export const deleteDepartment = id => async dispatch => {
  try {
    await dispatch({
      [CALL_API]: {
        url: `/department/${id}/remove`,
        method: "GET",
        types: [
          DELETE_DEPARTMENT,
          DELETE_DEPARTMENT_SUCCESS,
          DELETE_DEPARTMENT_FAILED
        ]
      }
    });
  } catch (error) {
    alert(error);
  }
};

export const getEmbedCode = id => async dispatch => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/department/${id}/embed-code`,
        method: "GET"
      }
    });
  } catch (error) {
    alert(error);
  }
};
