import React, { Component } from "react";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import {
  TODAY,
  YESTERDAY,
  THIS_WEEK,
  THIS_MONTH,
  LAST_WEEK,
  LAST_MONTH,
  RANGE
} from "../../store/types/reports";
import { connect } from "react-redux";
import { getReportsData, getDepartmentsList } from "../../store/actions";
import Charts from "./charts";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import moment from "moment";

class Reports extends Component {
  constructor(props) {
    super(props);
    const { departments } = this.props;
    this.state = {
      filterMenu: [
        { label: "Today", value: TODAY },
        { label: "Yesterday", value: YESTERDAY },
        { label: "This Week", value: THIS_WEEK },
        { label: "Last Week", value: LAST_WEEK },
        { label: "This Month", value: THIS_MONTH },
        { label: "Last Month", value: LAST_MONTH },
        { label: "Choose a range", value: RANGE }
      ],
      filteredValue: TODAY,
      departments: [
        { label: "All Departments", value: "all" },
        ...departments.map(department => ({
          label: department.name,
          value: department._id
        }))
      ],
      selectedDepartment: "all",
      open: false,
      defaultValue: moment(new Date()).format("YYYY-MM-DD"),
      fromMaxDate: moment(new Date()).format("YYYY-MM-DD"),
      toMaxDate: moment(new Date()).format("YYYY-MM-DD")
    };
  }

  componentDidMount() {
    this.props.getDepartmentsList();
    this.props.getReportsData({ day: this.state.filteredValue });
  }

  componentWillReceiveProps(nextProps) {
    const { departments = [] } = nextProps;
    this.setState({
      departments: [
        { label: "All Deparments", value: "all" },
        ...departments.map(department => ({
          label: department.name,
          value: department._id
        }))
      ],
      selectedDepartment:
        this.props.departments.length === nextProps.departments.length
          ? this.state.selectedDepartment
          : "all"
    });
  }

  handleChange = event => {
    const value = event.target.value;
    const params =
      this.state.selectedDepartment === "all"
        ? { day: value }
        : { day: value, departmentId: this.state.selectedDepartment };
    if (value === RANGE) {
      this.setState({ open: true, filteredValue: value });
      return;
    }
    this.props.getReportsData(params);
    this.setState({
      filteredValue: value
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  departmentChange = event => {
    this.setState({
      selectedDepartment: event.target.value
    });
    let params = {
      day: this.state.filteredValue
    };
    if (this.state.filteredValue === RANGE) {
      params = { ...params, start: this.state.from, end: this.state.to };
    }
    if (event.target.value !== "all") {
      params = { ...params, departmentId: event.target.value };
    }
    this.props.getReportsData(params);
  };

  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };

  changeFromDate = event => {
    const from = moment(new Date(event.target.value)).valueOf();
    this.setState({
      from,
      toMinDate: moment(new Date(event.target.value)).format("YYYY-MM-DD")
    });
  };

  changeToDate = event => {
    this.setState({ to: moment(new Date(event.target.value)).valueOf() });
  };

  submitRange = () => {
    this.setState({ open: false });
    let params = {
      day: this.state.filteredValue,
      start: this.state.from,
      end: this.state.to
    };

    if (this.state.selectedDepartment !== "all") {
      params = { ...params, departmentId: this.state.selectedDepartment };
    }
    this.props.getReportsData(params);
  };
  render() {
    const {
      filteredValue,
      filterMenu,
      departments,
      selectedDepartment,
      toMinDate,
      toMaxDate,
      fromMaxDate
    } = this.state;
    const toInputProps = !!toMinDate
      ? { max: toMaxDate, min: toMinDate }
      : { max: toMaxDate };
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 15,
            marginBottom: 15
          }}
        >
          <Select
            value={selectedDepartment}
            onChange={this.departmentChange}
            input={<OutlinedInput labelWidth={0} />}
            style={{
              width: 180,
              backgroundColor: "#f8f8f8",
              marginRight: 10
            }}
          >
            {departments.map((menuItem, i) => (
              <MenuItem value={menuItem.value} key={i}>
                {menuItem.label}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={filteredValue}
            onChange={this.handleChange}
            input={<OutlinedInput labelWidth={0} />}
            style={{ width: 180, backgroundColor: "#f8f8f8", marginRight: 10 }}
          >
            {filterMenu.map((menuItem, i) => (
              <MenuItem value={menuItem.value} key={i}>
                {menuItem.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <Charts data={[this.props.data]} />
        <Dialog open={this.state.open} onClose={this.handleClose}>
          <DialogContent>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <TextField
                id="date"
                label="From"
                type="date"
                onChange={this.changeFromDate}
                InputProps={{ inputProps: { max: fromMaxDate } }}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <div style={{ width: 10 }} />
              <TextField
                id="date"
                label="To"
                type="date"
                onChange={this.changeToDate}
                InputProps={{ inputProps: toInputProps }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} variant="outlined">
              Cancel
            </Button>
            <Button onClick={this.submitRange} variant="outlined">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connect(
  ({ departmentReducer, reportsReducer }) => {
    const { departments = [] } = departmentReducer;
    const { data } = reportsReducer;
    return { departments, data };
  },
  { getReportsData, getDepartmentsList }
)(Reports);
