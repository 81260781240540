export const GET_FAQS = "GET_FAQS";
export const GET_FAQS_SUCCESS = "GET_FAQS_SUCCESS";
export const GET_FAQS_FAILED = "GET_FAQS_FAILED";
export const GET_FAQ_DETAIL = "GET_FAQ_DETAIL";
export const GET_FAQ_DETAIL_SUCCESS = "GET_FAQ_DETAIL_SUCCESS";
export const GET_FAQ_DETAIL_FAILED = "GET_FAQ_DETAIL_FAILED";
export const CREATE_FAQ = "CREATE_FAQ";
export const CREATE_FAQ_SUCCESS = "CREATE_FAQ_SUCCESS";
export const CREATE_FAQ_FAILED = "CREATE_FAQ_FAILED";
export const DELETE_FAQ = "DELETE_FAQ";
export const DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS";
export const DELETE_FAQ_FAILED = "DELETE_FAQ_FAILED";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAILED = "GET_CATEGORIES_FAILED";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAILED = "CREATE_CATEGORY_FAILED";
export const GET_DEPT_FAQS = "get department Faqs";
export const GET_DEPT_FAQS_SUCCESS = "get department faqs success";
export const GET_DEPT_FAQS_FAILURE = "get department faqs failure";
