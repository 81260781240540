import React, { Component } from "react";
import "./App.css";
import { Route, Switch, Redirect } from "react-router-dom";
import { ConnectedRouter as Router } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import store, { persistor, history } from "./store";
import Login from "./components/auth/login";
import Register from "./components/auth/register";
import Dashboard from "./components/appRoutes/dashboard";
import ProtectedRoute from "./protectedRoute";

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <ProtectedRoute path="/" exact>
          <Redirect to="/dashboard" />
        </ProtectedRoute>
        <ProtectedRoute path="/dashboard" component={Dashboard} />
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
      </Switch>
    </Router>
  );
};

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Routes />
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
