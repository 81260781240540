import React, { Component } from "react";
import { connect } from "react-redux";
import { getFaqs, getFaqDetail, deleteFaq } from "../../../store/actions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { UpdateIconButton, CommonHeader } from "../../helpers/commonButtons";
import { MyDialog } from "../departments/list";

class FaqList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false
    };
  }

  goToCreate = () => {
    this.props.history.push({
      pathname: "/dashboard/faqs/create"
    });
  };

  componentDidMount() {
    this.props.getFaqs();
  }

  openModal = id => () => {
    this.setState({ openDialog: true, deleteId: id });
  };

  closeModal = () => {
    this.setState({ openDialog: false });
  };

  deleteFaq = () => {
    this.props.deleteFaq(this.state.deleteId);
    this.setState({ openDialog: false });
  };

  editFaq = faq => async () => {
    this.props.history.push({
      pathname: `/dashboard/faqs/${faq._id}/edit`
    });
  };

  render() {
    const { faqs } = this.props;
    return (
      <div style={{ padding: 15 }}>
        <CommonHeader header="List of Faqs" onClick={this.goToCreate} />
        {faqs.length > 0 && (
          <Paper>
            <Table className="w-100">
              <TableHead style={{ backgroundColor: "#d3eefd" }}>
                <TableRow>
                  <TableCell
                    className="w-25 places  text-center"
                    style={{ fontSize: 15, fontFamily: "OpenSans-SemiBold" }}
                  >
                    FAQ Title
                  </TableCell>
                  <TableCell
                    className="w-25 places  text-center"
                    style={{ fontSize: 15, fontFamily: "OpenSans-SemiBold" }}
                  >
                    Category
                  </TableCell>
                  <TableCell
                    className="w-25 places  text-center"
                    style={{ fontSize: 15, fontFamily: "OpenSans-SemiBold" }}
                  >
                    Department
                  </TableCell>
                  <TableCell
                    className="w-25 places text-center"
                    style={{ fontSize: 15, fontFamily: "OpenSans-SemiBold" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {faqs.map((faq, i) => (
                  <TableRow key={i}>
                    <TableCell
                      className="text-center"
                      style={{ fontSize: 15, fontFamily: "OpenSans-SemiBold" }}
                    >
                      {faq.article_title}
                    </TableCell>
                    <TableCell
                      className="text-center"
                      style={{ fontSize: 15, fontFamily: "OpenSans-SemiBold" }}
                    >
                      {faq.category.name}
                    </TableCell>
                    <TableCell
                      className="text-center"
                      style={{ fontSize: 15, fontFamily: "OpenSans-SemiBold" }}
                    >
                      {!faq.department
                        ? "All Departments"
                        : faq.department.name}
                    </TableCell>
                    <TableCell className="text-center">
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <UpdateIconButton
                          color="#e7f6f3"
                          onClick={this.editFaq(faq)}
                          image="/images/edit_icon.png"
                        />
                        <UpdateIconButton
                          color="#feecec"
                          onClick={this.openModal(faq._id)}
                          image="/images/delete_icon.png"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )}
        <MyDialog
          open={this.state.openDialog}
          closeModal={this.closeModal}
          submitModal={this.deleteFaq}
          title="Delete Department"
          content="Are you sure want to delete this FAQ?"
          submitButton="Yes"
          cancelButton="No"
        />
      </div>
    );
  }
}

export default connect(
  ({ faqReducer }) => {
    const { faqs, page, pages } = faqReducer;
    return { faqs, page, pages };
  },
  { getFaqs, getFaqDetail, deleteFaq }
)(FaqList);
