import React, { Component } from "react";
import "@material/react-layout-grid/dist/layout-grid.css";
import { Cell, Grid, Row } from "@material/react-layout-grid";
import { MyInputText } from "../formInputs/inputText";

import { CardView } from "../formInputs/cardView";

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      userName: ""
    };
  }

  onChangeValue = label => e => {
    this.setState({ [label]: e.target.value });
  };

  render() {
    const { email, password, userName } = this.state;
    return (
      <div
        style={{
          height: "100vh",
          backgroundImage: "linear-gradient(to top right, #0176BB,#03AADC)"
        }}
      >
        <Grid>
          <Row>
            <Cell desktopColumns={3} phoneColumns={1} tabletColumns={2} />
            <Cell desktopColumns={6} phoneColumns={10} tabletColumns={8}>
              <CardView
                heading="Register"
                buttonLabel="Register"
                icon="Go to Login"
                link="/login"
              >
                <Row>
                  <Cell columns={2} />
                  <Cell columns={8}>
                    <div
                      style={{
                        fontSize: 15,
                        fontFamily: "OpenSans-Regular",
                        marginBottom: 0,
                        marginTop: 30
                      }}
                    >
                      Email
                    </div>
                    <MyInputText
                      value={email}
                      onChange={this.onChangeValue("email")}
                    />
                    <div
                      style={{
                        fontSize: 15,
                        fontFamily: "OpenSans-Regular",
                        marginBottom: 0,
                        marginTop: 30
                      }}
                    >
                      User Name
                    </div>
                    <MyInputText
                      value={userName}
                      onChange={this.onChangeValue("userName")}
                    />
                    <div
                      style={{
                        fontSize: 15,
                        fontFamily: "OpenSans-Regular",
                        marginBottom: 0,
                        marginTop: 30
                      }}
                    >
                      password
                    </div>
                    <MyInputText
                      value={password}
                      onChange={this.onChangeValue("password")}
                    />
                  </Cell>
                  <Cell columns={2} />
                </Row>
              </CardView>
            </Cell>
            <Cell desktopColumns={3} phoneColumns={1} tabletColumns={2} />
          </Row>
        </Grid>
      </div>
    );
  }
}
