import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default class TextEditor extends Component {
  render() {
    return (
      <Editor
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "colorPicker",
            "link",
            "embedded",
            "emoji",
            "image",
            "list",
            "textAlign",
            "remove",
            "history"
          ],
          image: {
            uploadCallback: this.props.uploadImageCallBack,
            alt: { present: true, mandatory: true },
            previewImage: true,
            defaultSize: {
              width: "100%"
            }
          }
        }}
        editorState={this.props.editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={this.props.onEditorStateChange}
      />
    );
  }
}
