import {
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_SUCCESS,
  OFFLINE,
  CHANGE_STATUS_SUCCESS,
  ONLINE,
  SET_TOKEN
} from "../types";

const initialState = {
  token: null,
  status: OFFLINE
};

export function authReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOGIN_SUCCESS: {
      const { token } = action;
      const data = JSON.parse(atob(token.split(".")[1]));
      const { role, user, name } = data;
      return { ...state, token, role, user, name, status: ONLINE };
    }
    case CHANGE_STATUS_SUCCESS: {
      const { status } = action;
      return { ...state, status };
    }
    case USER_LOGOUT_SUCCESS: {
      const state = initialState;
      return state;
    }
    case SET_TOKEN: {
      const { token } = action;
      return { ...state, token };
    }
    default:
      return state;
  }
}
