import React from "react";
import { Route } from "react-router";
import ModalSwitch from "../../../modalRoute";
import Settings from "./settings";

export default function ChatRouter(props) {
  const { location, history } = props;
  return (
    <ModalSwitch location={location} history={history}>
      <Route path="/dashboard/settings" component={Settings} />
    </ModalSwitch>
  );
}
