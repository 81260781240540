import { CALL_API } from "../api.middleware";
import {
  GET_REPORTS_DATA,
  GET_REPORTS_DATA_SUCCESS,
  GET_REPORTS_DATA_FAILED
} from "../types/reports";

export const getReportsData = params => async dispatch => {
  try {
    await dispatch({
      [CALL_API]: {
        url: `/reports/getReports`,
        method: "GET",
        types: [
          GET_REPORTS_DATA,
          GET_REPORTS_DATA_SUCCESS,
          GET_REPORTS_DATA_FAILED
        ],
        params
      }
    });
  } catch (error) {
    alert(error);
  }
};
