import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { styles } from "./homeStyles";
import RealTimeDashboard from "./realTimeDashboard";
import DashboardTable from "./dashboardTable";
import openSocket from "socket.io-client";
import { connect } from "react-redux";
import { REAL_TIME_MONITORING } from "../../store/types/socket";
import Charts from "./charts";
import Reports from "./reports";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      realTimeData: {}
    };
    const { token } = this.props;
    this.socket = openSocket("/", {
      query: {
        token
      }
    });
    this.handleRealTimeData = this.handleRealTimeData.bind(this);
  }

  componentDidMount() {
    this.socket.on(REAL_TIME_MONITORING, this.handleRealTimeData);
  }

  handleRealTimeData(realTimeData) {
    const { att, ...others } = realTimeData;
    this.setState({ realTimeData: others });
  }

  render() {
    const { classes } = this.props;
    const { realTimeData } = this.state;
    return (
      <div>
        <div className={classes.navbar}>
          <div className={classes.heading}> Real-time DashBoard</div>
          {/* <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <Checkbox
                style={{ padding: 0 }}
                checked={true}
                // value={true}
                color="primary"
              />
            </div>
            <div className={classes.radiobutton}>Include Bot</div>
          </div> */}
        </div>
        <RealTimeDashboard classes={classes} realTimeData={realTimeData} />
        <Reports />
        {/* <Charts /> */}
        <DashboardTable classes={classes} />
      </div>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  connect(({ authReducer }) => {
    const { token, user, role, name } = authReducer;
    return { token, role, user, name };
  })(Home)
);
