import React from "react";
import { Route } from "react-router";
import OperatorsList from "./list";
import CreateOperator from "./create";
import ModalSwitch from "../../../modalRoute";

export default function OperatorRouter(props) {
  const { location, history } = props;
  return (
    <ModalSwitch location={location} history={history}>
      <Route path="/dashboard/operators/create" component={CreateOperator} />
      <Route
        path="/dashboard/operators/:operatorId/edit"
        component={CreateOperator}
      />
      <Route path="/dashboard/operators" component={OperatorsList} />
    </ModalSwitch>
  );
}
