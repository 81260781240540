import { GET_SETTINGS_DATA_SUCCESS } from "../types";

export function settingsReducer(state = {}, action) {
  switch (action.type) {
    case GET_SETTINGS_DATA_SUCCESS: {
      const { response, type, ...others } = action;
      if (response) {
        return { ...state, ...response };
      } else {
        return { ...state, ...others };
      }
    }
    default:
      return state;
  }
}
