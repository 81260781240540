import React from "react";
import { Route } from "react-router";
import DepartmentList from "./list";
import EditDepartment from "./edit";
import CreateDepartment from "./create";
import ModalSwitch from "../../../modalRoute";

export default function DepartmentRouter(props) {
  const { location, history } = props;
  return (
    <ModalSwitch location={location} history={history}>
      <Route
        path="/dashboard/departments/create"
        component={CreateDepartment}
      />
      <Route
        path="/dashboard/departments/:departmentId/edit"
        component={EditDepartment}
      />
      <Route path="/dashboard/departments" component={DepartmentList} />
    </ModalSwitch>
  );
}
