import React from "react";
import { Route } from "react-router";
import ModalSwitch from "../../../modalRoute";
import AdminChat from "./adminChat";

export default function ChatRouter(props) {
  const { location, history } = props;
  return (
    <ModalSwitch location={location} history={history}>
      <Route
        path="/dashboard/conversations/:conversationId"
        component={AdminChat}
      />
      <Route path="/dashboard/conversations" component={AdminChat} />
    </ModalSwitch>
  );
}
