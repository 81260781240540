import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const CustomTableCell = withStyles(() => ({
  head: {
    backgroundColor: "white",
    color: "black"
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

export default class DashboardTable extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.tabularform}>
        <Card className={classes.tabularcard}>
          <CardContent className={classes.padding}>
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className={classes.tableheading}>Daywise Report</div>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#3571d9",
                    color: "white",
                    fontSize: 12,
                    fontFamily: "OpenSans-SemiBold"
                  }}
                  className="button-outline"
                >
                  Download as Excel
                  {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
                  {/* <Icon className={classes.rightIcon}>send</Icon>
                   */}
                  <img
                    src="/images/down-arrow.png"
                    style={{ height: 15, width: 15, marginLeft: 10 }}
                    alt=""
                  />
                </Button>
              </div>
            </div>
            <div className={classes.tablesubheading}>
              The table shows the daywise activity for last seven days :-
            </div>
            <Table style={{ border: "1px solid #d8edf9" }}>
              <TableHead>
                <TableRow style={{ width: "100%" }}>
                  <CustomTableCell align="center" className={classes.padding}>
                    Days
                  </CustomTableCell>
                  <CustomTableCell align="center" className={classes.padding}>
                    Chats
                  </CustomTableCell>
                  <CustomTableCell align="center" className={classes.padding}>
                    First Response (Avg.)
                  </CustomTableCell>
                  <CustomTableCell align="center" className={classes.padding}>
                    Response (Avg.){" "}
                  </CustomTableCell>
                  <CustomTableCell align="center" className={classes.padding}>
                    Resolution
                  </CustomTableCell>
                  <CustomTableCell align="center" className={classes.padding}>
                    CSAT
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={classes.row}>
                  <CustomTableCell>Apr 08</CustomTableCell>
                  <CustomTableCell align="center">52 sec</CustomTableCell>
                  <CustomTableCell align="center">52 sec</CustomTableCell>
                  <CustomTableCell align="center">2 hr 20 min</CustomTableCell>
                  <CustomTableCell align="center">10%</CustomTableCell>
                  <CustomTableCell align="center">64%</CustomTableCell>
                </TableRow>

                <TableRow className={classes.row}>
                  <CustomTableCell>Apr 07</CustomTableCell>
                  <CustomTableCell align="center">52 sec</CustomTableCell>
                  <CustomTableCell align="center">52 sec</CustomTableCell>
                  <CustomTableCell align="center">2 hr 20 min</CustomTableCell>
                  <CustomTableCell align="center">10%</CustomTableCell>
                  <CustomTableCell align="center">64%</CustomTableCell>
                </TableRow>

                <TableRow className={classes.row}>
                  <CustomTableCell>Apr 06</CustomTableCell>
                  <CustomTableCell align="center">52 sec</CustomTableCell>
                  <CustomTableCell align="center">52 sec</CustomTableCell>
                  <CustomTableCell align="center">2 hr 20 min</CustomTableCell>
                  <CustomTableCell align="center">10%</CustomTableCell>
                  <CustomTableCell align="center">64%</CustomTableCell>
                </TableRow>

                <TableRow className={classes.row}>
                  <CustomTableCell>Apr 05</CustomTableCell>
                  <CustomTableCell align="center">52 sec</CustomTableCell>
                  <CustomTableCell align="center">52 sec</CustomTableCell>
                  <CustomTableCell align="center">2 hr 20 min</CustomTableCell>
                  <CustomTableCell align="center">10%</CustomTableCell>
                  <CustomTableCell align="center">64%</CustomTableCell>
                </TableRow>

                <TableRow className={classes.row}>
                  <CustomTableCell>Apr 04</CustomTableCell>
                  <CustomTableCell align="center">52 sec</CustomTableCell>
                  <CustomTableCell align="center">52 sec</CustomTableCell>
                  <CustomTableCell align="center">2 hr 20 min</CustomTableCell>
                  <CustomTableCell align="center">10%</CustomTableCell>
                  <CustomTableCell align="center">64%</CustomTableCell>
                </TableRow>

                <TableRow className={classes.row}>
                  <CustomTableCell>Apr 03</CustomTableCell>
                  <CustomTableCell align="center">52 sec</CustomTableCell>
                  <CustomTableCell align="center">52 sec</CustomTableCell>
                  <CustomTableCell align="center">2 hr 20 min</CustomTableCell>
                  <CustomTableCell align="center">10%</CustomTableCell>
                  <CustomTableCell align="center">64%</CustomTableCell>
                </TableRow>

                <TableRow className={classes.row}>
                  <CustomTableCell>Apr 02</CustomTableCell>
                  <CustomTableCell align="center">52 sec</CustomTableCell>
                  <CustomTableCell align="center">52 sec</CustomTableCell>
                  <CustomTableCell align="center">2 hr 20 min</CustomTableCell>
                  <CustomTableCell align="center">10%</CustomTableCell>
                  <CustomTableCell align="center">64%</CustomTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
        <Card className={classes.tabularcard}>
          <div className={classes.tableheading}>Daywise Chart</div>
          <div className={classes.tablesubheading}>
            The chart shows the Day activity of one last day
          </div>

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 30
              }}
            >
              <img
                src="/images/chart.png"
                alt=""
                style={{ height: 225, width: 225 }}
              />
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 50
                }}
              >
                <div
                  style={{
                    display: "flex",
                    margin: 10,
                    marginRight: 15,
                    alignItems: "flex-start"
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#36a0ff",
                      height: 15,
                      width: 15,
                      borderRadius: 2,
                      marginRight: 5,
                      marginTop: 3,
                      display: "flex",
                      flexShrink: 0
                    }}
                  />
                  <div style={{ fontSize: 12, fontFamily: "OpenSans-Regular" }}>
                    Request for CAS
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    margin: 10,
                    marginRight: 15,
                    alignItems: "flex-start"
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#ffa645",
                      height: 15,
                      width: 15,
                      borderRadius: 2,
                      marginRight: 5,
                      marginTop: 3,
                      display: "flex",
                      flexShrink: 0
                    }}
                  />
                  <div style={{ fontSize: 12, fontFamily: "OpenSans-Regular" }}>
                    Account opening procedure
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    margin: 10,
                    marginRight: 15,
                    alignItems: "flex-start"
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#3ab5ca",
                      height: 15,
                      width: 15,
                      borderRadius: 2,
                      marginRight: 5,
                      marginTop: 3,
                      display: "flex",
                      flexShrink: 0
                    }}
                  />
                  <div style={{ fontSize: 12, fontFamily: "OpenSans-Regular" }}>
                    Request for account statement
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    display: "flex",
                    margin: 10,
                    marginRight: 15,
                    alignItems: "flex-start"
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#1f3bb3",
                      height: 15,
                      width: 15,
                      borderRadius: 2,
                      marginRight: 5,
                      marginTop: 3,
                      display: "flex",
                      flexShrink: 0
                    }}
                  />
                  <div style={{ fontSize: 12, fontFamily: "OpenSans-Regular" }}>
                    Login issues on Karvymfs website
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    margin: 10,
                    marginRight: 15,
                    alignItems: "flex-start"
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#7964d3",
                      height: 15,
                      width: 15,
                      borderRadius: 2,
                      marginRight: 5,
                      marginTop: 3,
                      display: "flex",
                      flexShrink: 0
                    }}
                  />
                  <div style={{ fontSize: 12, fontFamily: "OpenSans-Regular" }}>
                    {" "}
                    Procedure to change contact details
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}
