import React, { Component } from "react";
import { MyInputText } from "../../formInputs/inputText";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { createDepartment } from "../../../store/actions";

class CreateDepartment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departmentName: ""
    };
  }

  submit = async () => {
    const payload = { name: this.state.departmentName };
    const response = await this.props.createDepartment(payload);
    if (!!response) {
      this.props.history.goBack();
    }
  };

  keyPress = e => {
    if (e.key === "Enter") {
      // Do code here

      this.submit();

      e.preventDefault();
    }
  };

  onChangeValue = e => {
    this.setState({ departmentName: e.target.value });
  };
  render() {
    return (
      <div>
        <div style={{ fontSize: 15, fontFamily: "OpenSans-Regular" }}>
          department name
        </div>
        <MyInputText
          value={this.state.departmentName}
          onChange={this.onChangeValue}
          onKeyPress={this.keyPress}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="button-style"
            variant="outlined"
            onClick={this.submit}
            style={{ marginTop: 10 }}
          >
            Create
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { createDepartment }
)(CreateDepartment);
