import React, { Component } from "react";
import { messageStyles } from "./chatStyles";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";

class LocalFile extends Component {
  constructor() {
    super();
    this.onChangeRef = this.onChangeRef.bind(this);
  }

  onChangeRef = element => {
    console.log(element, "element");
    this.imageEl = element;
    if (!element) {
      console.error("imageEl is null");
      return;
    }
    const file = this.props.file;
    const isImage = file.type.startsWith("image");
    if (isImage) {
      console.log("hiii");
      const reader = new FileReader();
      reader.onload = e => {
        if (this.imageEl == null) {
          return;
        }
        this.imageEl.src = e.target.result;
        this.props.onImageLoaded && this.props.onImageLoaded();
      };
      reader.readAsDataURL(file);
    }
  };

  render() {
    const { file, percent = 0, classes } = this.props;

    return (
      <div
        className={classes.messageBox}
        style={{ justifyContent: "flex-end", flex: 1 }}
      >
        <div
          style={{
            marginRight: 10
          }}
        >
          <div className={classes.messageText}>
            <div className={classes.media}>
              File: {file.name}
              <img
                ref={this.onChangeRef}
                style={{ width: "100%" }}
                // ref={element => (this.imageEl = element)}
                alt="uploaded-img"
              />
              <div className={classes.progressContainer}>
                <div
                  className={classes.progress}
                  style={{ width: `${percent}%` }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <Avatar
          alt="Remy Sharp"
          src="/images/user.png"
          className={classes.avatar}
          style={{ alignSelf: "flex-start" }}
        /> */}
      </div>
    );
  }
}

LocalFile.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(messageStyles)(LocalFile);
