import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Grid from "@material-ui/core/Grid";
import {
  getDepartmentsList,
  deleteDepartment,
  getEmbedCode
} from "../../../store/actions";
import { COPY, COPIED } from "../../../store/types";
import { CommonHeader, UpdateIconButton } from "../../helpers/commonButtons";

class DepartmentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      buttonTexts: [],
      expanded: null
    };
  }

  goToCreate = () => {
    this.props.history.push({
      pathname: "/dashboard/departments/create",
      state: { modal: true, heading: "New Department" }
    });
  };

  componentDidMount() {
    this.props.getDepartmentsList();
  }

  componentWillReceiveProps(nextProps) {
    const { departments = [] } = nextProps;
    const buttonTexts =
      departments.length > 0 ? departments.map(dept => COPY) : [];
    this.setState({ buttonTexts });
  }

  closeModal = () => {
    this.setState({ openDialog: false });
  };

  editDepartment = department => async e => {
    e.stopPropagation();
    this.props.history.push({
      pathname: `/dashboard/departments/${department._id}/edit`,
      state: { modal: true, heading: "Edit Department" }
    });
  };

  openModal = id => e => {
    e.stopPropagation();
    this.setState({ openDialog: true, deleteId: id });
  };

  deleteDepartment = () => {
    this.props.deleteDepartment(this.state.deleteId);
    this.closeModal();
  };

  openWidget = (panel, index) => async event => {
    const { expanded } = this.state;
    if (expanded !== panel) {
      const response = await this.props.getEmbedCode(panel);
      this.setState({
        buttonTexts: [
          ...this.state.buttonTexts.slice(0, index),
          COPY,
          ...this.state.buttonTexts.slice(index + 1)
        ],
        expanded: panel,
        code: response.code
      });
      return;
    }
    this.setState({
      expanded: false,
      code: ""
    });
  };

  copyToClipboard = index => () => {
    var textField = document.createElement("textarea");
    textField.innerText = this.state.code;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    this.setState({
      buttonTexts: [
        ...this.state.buttonTexts.slice(0, index),
        COPIED,
        ...this.state.buttonTexts.slice(index + 1)
      ]
    });
  };

  render() {
    const { departments } = this.props;
    const { buttonTexts = [], expanded } = this.state;
    return (
      <div style={{ padding: 15 }}>
        <CommonHeader header="List of Departments" onClick={this.goToCreate} />
        <div style={{ borderRadius: 10 }}>
          <div>
            {departments.length > 0 && (
              <div>
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "#d3eefd",
                    border: "1px solid lightgray",
                    paddingTop: 20,
                    paddingBottom: 20
                  }}
                >
                  <Grid container spacing={8}>
                    <Grid
                      item
                      xs={4}
                      style={{
                        textAlign: "center",
                        fontSize: 18,
                        fontFamily: "OpenSans-SemiBold"
                      }}
                    >
                      Department Name
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        textAlign: "center",
                        fontSize: 15,
                        fontFamily: "OpenSans-SemiBold"
                      }}
                    >
                      Operators
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        textAlign: "center",
                        fontSize: 15,
                        fontFamily: "OpenSans-SemiBold"
                      }}
                    >
                      Actions
                    </Grid>
                  </Grid>
                </div>
                <div style={{ width: "100%" }}>
                  {departments.map((department, i) => (
                    <ExpansionPanel
                      square
                      key={i}
                      expanded={expanded === department._id}
                      style={{
                        boxShadow: "0px 0px 0px 0px",
                        border: "1px solid lightgray",
                        borderTop: "0px",
                        padding: 0
                      }}
                    >
                      <ExpansionPanelSummary>
                        <Grid
                          container
                          spacing={8}
                          style={{ padding: 0, margin: 0 }}
                        >
                          <Grid
                            item
                            xs={4}
                            style={{
                              textAlign: "center",
                              fontSize: 15,
                              fontFamily: "OpenSans-Bold",
                              paddingTop: 25,
                              paddingBottom: 25
                            }}
                          >
                            {department.name}
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            style={{
                              textAlign: "left",
                              paddingTop: 20,
                              paddingBottom: 20
                            }}
                          >
                            {department.agents.length > 0 &&
                              department.agents.map((agent, index) => (
                                <Chip
                                  avatar={
                                    <Avatar
                                      style={{ backgroundColor: "transparent" }}
                                    >
                                      <Icon>account_circle</Icon>
                                    </Avatar>
                                  }
                                  id={agent._id}
                                  key={index}
                                  label={agent.name}
                                  style={{
                                    margin: 2,
                                    paddingRight: 30,
                                    fontFamily: "OpenSans-Regular",
                                    fontSize: 12
                                  }}
                                />
                              ))}
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            style={{
                              paddingTop: 25,
                              paddingBottom: 25
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center"
                              }}
                            >
                              <UpdateIconButton
                                color="#e7f6f3"
                                onClick={this.editDepartment(department)}
                                image="/images/edit_icon.png"
                              />
                              <UpdateIconButton
                                color="#feecec"
                                onClick={this.openModal(department._id)}
                                image="/images/delete_icon.png"
                              />
                              <div>
                                <Button
                                  className="button-style"
                                  variant="outlined"
                                  onClick={this.openWidget(department._id, i)}
                                  style={{
                                    color: "#204275",
                                    borderColor: "#204275"
                                  }}
                                >
                                  Get Widget Code
                                </Button>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Grid container spacing={8}>
                          <Grid item xs={9}>
                            <Paper style={{ padding: 5 }} elevation={1}>
                              {this.state.code}
                            </Paper>
                          </Grid>
                          {buttonTexts.length > 0 && (
                            <Grid item xs={3}>
                              <Button
                                onClick={this.copyToClipboard(i)}
                                className="button-style"
                                variant="outlined"
                              >
                                {buttonTexts[i]}
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  ))}
                </div>
              </div>
            )}
          </div>
          <MyDialog
            open={this.state.openDialog}
            closeModal={this.closeModal}
            submitModal={this.deleteDepartment}
            title="Delete Department"
            content="Are you sure want to delete this department?"
            submitButton="Yes"
            cancelButton="No"
          />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ departmentReducer }) => {
    const { departments = [] } = departmentReducer;
    return { departments };
  },
  {
    getDepartmentsList,
    deleteDepartment,
    getEmbedCode
  }
)(DepartmentList);

export function MyDialog(props) {
  const {
    open,
    closeModal,
    submitModal,
    title,
    content,
    submitButton,
    cancelButton
  } = props;
  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={closeModal} variant="outlined">
          {cancelButton}
        </Button>
        <Button onClick={submitModal} variant="outlined">
          {submitButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
