import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

export default class RealTimeDashboard extends Component {
  render() {
    const { classes, realTimeData } = this.props;
    const keys = Object.keys(realTimeData);
    return (
      <div className={classes.showcards}>
        {keys.length > 0 &&
          keys.map((key, index) => (
            <CardComponent
              key={index}
              classes={classes}
              color="#0FA1EA"
              heading={captalize(key)}
              content={realTimeData[key]}
            />
          ))}
      </div>
    );
  }
}

const captalize = key => {
  const content = key
    .split("_")
    .map(value => value.charAt(0).toUpperCase() + value.slice(1))
    .join(" ");
  return content;
};

function CardComponent(props) {
  const { heading, content, color, classes } = props;
  return (
    <Card className={classes.card} style={{ backgroundColor: color }}>
      <CardContent className={classes.main}>{heading}</CardContent>
      <CardContent className={classes.sub}>{content}</CardContent>
    </Card>
  );
}
