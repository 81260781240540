import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Switch } from "react-router-dom";

export default class ModalSwitch extends Component {
  previousLocation = this.props.location;

  componentWillUpdate(nextProps) {
    let { location } = this.props;
    // set previousLocation if props.location is not modal
    if (
      nextProps.history.action !== "POP" &&
      (!location.state || !location.state.modal)
    ) {
      this.previousLocation = this.props.location;
    }
  }

  closeModal = () => {
    this.props.history.goBack();
  };

  render() {
    const { location, children } = this.props;
    const isModal =
      !!location.state &&
      !!location.state.modal &&
      this.previousLocation !== location;
    return (
      <div>
        <Switch location={isModal ? this.previousLocation : location}>
          {children}
        </Switch>
        {isModal ? (
          <Dialog open={true} onClose={this.closeModal} maxWidth="sm" fullWidth={true}>
            <DialogTitle>{location.state.heading}</DialogTitle>
            <DialogContent>
              <Switch location={location}>{children}</Switch>
            </DialogContent>
          </Dialog>
        ) : null}
      </div>
    );
  }
}
