export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILED = "USER_LOGIN_FAILED";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const CHANGE_STATUS = "CHANGE_STATUS";
export const CHANGE_STATUS_SUCCESS = "CHANGE_STATUS_SUCCESS";
export const CHANGE_STATUS_FAILED = "CHANGE_STATUS_FAILED";

export const SET_TOKEN = "SET_TOKEN";
export const SET_TOKEN_SUCCESS = "SET_TOKEN_SUCCESS";
export const SET_TOKEN_FAILURE = "SET_TOKEN_FAILURE";
