import React, { Component } from "react";
import { MyInputText } from "../../formInputs/inputText";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import {
  editDepartment,
  getOperators,
  getDepartmentDetail
} from "../../../store/actions";
import MyAutoComplete from "../../formInputs/autoComplete";
class EditDepartment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departmentName: "",
      id: "",
      selectedItem: [],
      agents: [],
      inputValue: ""
    };
  }

  async componentDidMount() {
    const { params } = this.props.match;
    this.props.getOperators();
    const response = await this.props.getDepartmentDetail(params.departmentId);
    const { name, agents = [], _id } = response;
    this.setState({
      departmentName: name,
      id: _id,
      selectedItem: agents
    });
  }

  componentWillReceiveProps(nextProps) {
    let { agents = [] } = nextProps;
    this.setState({ agents });
  }

  handleKeyDown = event => {
    const { inputValue, selectedItem } = this.state;
    if (
      selectedItem.length &&
      !inputValue.length &&
      event.key === "Backspace"
    ) {
      this.setState({
        selectedItem: selectedItem.slice(0, selectedItem.length - 1)
      });
    }
  };

  handleInputChange = event => {
    this.setState({ inputValue: event.target.value });
  };

  handleChange = item => {
    let { selectedItem } = this.state;
    const selectedItemIds = selectedItem.map(i => i._id);
    if (selectedItemIds.indexOf(item._id) === -1) {
      selectedItem = [...selectedItem, item];
    }
    this.setState({
      inputValue: "",
      selectedItem
    });
  };

  handleDelete = item => () => {
    let { selectedItem } = this.state;
    selectedItem = selectedItem.filter(i => i._id !== item._id);
    this.setState({
      selectedItem
    });
  };

  submit = async () => {
    const { id, departmentName: name, selectedItem } = this.state;
    const payload = { name, agents: selectedItem.map(({ _id }) => _id) };
    await this.props.editDepartment(payload, id);
    this.props.history.goBack();
  };

  onChangeValue = e => {
    this.setState({ departmentName: e.target.value });
  };

  render() {
    const { agents = [], selectedItem = [], inputValue } = this.state;
    return (
      <div>
        <MyInputText
          label="Department Name"
          value={this.state.departmentName}
          onChange={this.onChangeValue}
        />
        <MyAutoComplete
          suggestions={agents}
          selectedItem={selectedItem}
          inputValue={inputValue}
          handleChange={this.handleChange}
          handleDelete={this.handleDelete}
          handleKeyDown={this.handleKeyDown}
          handleInputChange={this.handleInputChange}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="button-style"
            variant="outlined"
            onClick={this.submit}
          >
            Update
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ operatorReducer }) => {
    const { agents = [] } = operatorReducer;
    return { agents };
  },
  { editDepartment, getOperators, getDepartmentDetail }
)(EditDepartment);
