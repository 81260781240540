import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  TEXT,
  IMAGE,
  FILE,
  UPDATES,
  ADMINS_ONLY,
  AGENTS_ONLY,
  OPTIONS,
  OPTIONS_RESPONSE,
  INPUT,
  BALANCE_LIST,
  FEEDBACK,
  USER,
  LEAVE
} from "../../../store/types/socket";
import { ADMIN, AGENT } from "../../../store/types";
import StarRating from "../../../starRating";
import { messageStyles } from "./chatStyles";
import DayPicker, { DateUtils } from "react-day-picker";
// import "react-day-picker/lib/style.css";
import { MyTimeFormat, MyChatImage, MyChipSet } from "./timeFormat";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Divider,
  Chip,
  Dialog,
  DialogContent,
  DialogActions
} from "@material-ui/core";

const easeInOutQuad = function(t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};
// const tableData = [];
// const tableData = [
//   {
//     fund: "108 ",
//     invname: "Y N V BHARAT",
//     schemedesc: "UTI-Mid Cap Fund GROWTH PLAN",
//     balunits: 0,
//     curamt: 0
//   },
//   {
//     fund: "117  ",
//     invname: "Y N V BHARAT",
//     schemedesc: "Mirae Asset Tax Saver Fund  Direct Growth",
//     balunits: 270.59,
//     curamt: 5011.33
//   },
//   {
//     fund: "127  ",
//     invname: "Y N V BHARAT",
//     schemedesc: "Motilal Oswal Long Term Equity Fund  Direct",
//     balunits: 0,
//     curamt: 0
//   },
//   {
//     fund: "127  ",
//     invname: "Y N V BHARAT",
//     schemedesc: "Motilal Oswal Long Term Equity Fund  Direct",
//     balunits: 137.88,
//     curamt: 2476.83
//   },
//   {
//     fund: "128  ",
//     invname: "Y N V BHARAT",
//     schemedesc: "Axis Long Term Equity Fund  Growth",
//     balunits: 579.01,
//     curamt: 25643.66
//   },
//   {
//     fund: "128  ",
//     invname: "Y N V BHARAT",
//     schemedesc: "Axis Long Term Equity Fund  Direct Growth",
//     balunits: 376.2,
//     curamt: 17902.23
//   },
//   {
//     fund: "RMF",
//     invname: "Y N V BHARAT",
//     schemedesc:
//       "RELIANCE REGULAR SAVINGS FUND - DEBT PLAN GROWTH PLAN GROWTH OPTION ",
//     balunits: 65.06,
//     curamt: 1675.65
//   }
// ];
class Message extends Component {
  constructor(props) {
    super(props);
    this.scrollLeft = this.scrollLeft.bind(this);
    this.scrollRight = this.scrollRight.bind(this);
    this.scrollHorizontallyBy = this.scrollHorizontallyBy.bind(this);
    this.scroll = this.scroll.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.state = {
      selectedDays: [],
      open: false
    };
  }

  scroll() {
    const container = this.carouselDiv;
    const scrollLeft = container.scrollLeft;
    const containerWidth = container.getBoundingClientRect().width;
    const childWidths = Array.prototype.map.call(
      container.children,
      child => child.getBoundingClientRect().width
    );
    const accumulatedWidths = childWidths.map((width, index, arr) =>
      arr.slice(0, index + 1).reduce((x, y) => x + y, 0)
    );
    return { scrollLeft, containerWidth, accumulatedWidths };
  }

  scrollLeft() {
    const container = this.carouselDiv;
    if (!!this.carouselDiv) {
      const { scrollLeft, containerWidth, accumulatedWidths } = this.scroll();
      const childrenPastLeftEdge = accumulatedWidths.filter(
        width => width >= scrollLeft
      );
      const newScrollLeft = Math.max(
        0,
        childrenPastLeftEdge.shift() - containerWidth
      );
      this.scrollHorizontallyBy(
        container,
        newScrollLeft - container.scrollLeft,
        300
      );
    }
  }

  scrollRight() {
    const container = this.carouselDiv;
    if (!!this.carouselDiv) {
      const { scrollLeft, containerWidth, accumulatedWidths } = this.scroll();
      const childrenTillRightEdge = accumulatedWidths.filter(
        width => width <= scrollLeft + containerWidth
      );
      const newScrollLeft = Math.min(
        accumulatedWidths.pop() - containerWidth,
        childrenTillRightEdge.pop()
      );
      this.scrollHorizontallyBy(
        container,
        newScrollLeft - container.scrollLeft,
        300
      );
    }
  }

  scrollHorizontallyBy(element, deltaX, duration) {
    let start = element.scrollLeft,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, deltaX, duration);
      element.scrollLeft = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }

  handleDayClick(day, { selected }) {
    const { selectedDays } = this.state;
    if (selected) {
      const selectedIndex = selectedDays.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }
    this.setState({ selectedDays });
  }

  render() {
    const {
      classes,
      role,
      onOptionSelect,
      userChat,
      roomStatus,
      index,
      lastIndex
    } = this.props;
    const { selectedDays } = this.state;
    const message_types = [UPDATES, AGENTS_ONLY, ADMINS_ONLY];
    let {
      incoming,
      type = "text",
      content,
      createdAt,
      fileName,
      userId
    } = this.props.message;

    if (
      type === OPTIONS ||
      type === OPTIONS_RESPONSE ||
      type === INPUT ||
      type === BALANCE_LIST ||
      type === FEEDBACK ||
      type === LEAVE
    ) {
      content = JSON.parse(content);
    }

    return (
      <div>
        {message_types.includes(type) ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {type === ADMINS_ONLY && role === ADMIN && (
              <p
                className={classNames(
                  classes.messageContent,
                  classes.statusContent
                )}
              >
                {content}
              </p>
            )}
            {type === AGENTS_ONLY && !userChat && (
              <p
                className={classNames(
                  classes.messageContent,
                  classes.statusContent
                )}
              >
                {content}
              </p>
            )}
            {type === UPDATES && (
              <div className={classes.updatediv}>
                {/* <div style={{height:1,width:1,flexGrow:1}}/> */}
                <Divider className={classes.updatedivider} />
                <div
                  className={classNames(
                    classes.messageContent,
                    classes.statusContent,
                    classes.updatecontent
                  )}
                >
                  {content}
                </div>
                <Divider className={classes.updatedivider} />
              </div>
            )}
          </div>
        ) : (
          <div className="messagebox">
            <div
              className={classes.messageBox}
              style={{
                justifyContent: !!incoming ? "flex-start" : "flex-end",
                display: "flex"
              }}
            >
              {!!incoming && type !== FEEDBACK && (
                <MyChatImage userId={userId} />
              )}
              <div
                style={{
                  display:
                    type === BALANCE_LIST || type === FEEDBACK
                      ? "none"
                      : "flex",
                  marginRight: !!incoming ? 0 : 10,
                  marginLeft: !!incoming ? 10 : 0
                }}
              >
                <div
                  className={classes.messageText}
                  style={{
                    backgroundColor: !!incoming ? "#f3a61d" : "#4f1741",
                    color: !!incoming ? "#4f1741" : "white",
                    borderTopRightRadius: !!incoming ? 20 : 0,
                    borderTopLeftRadius: !!incoming ? 0 : 20,
                    fontSize: 12
                  }}
                >
                  {type === TEXT && (
                    <p className={classes.messageContent}>{content}</p>
                  )}
                  {type === IMAGE && (
                    <div className={classes.media}>
                      <div>
                        <a
                          rel="noopener noreferrer"
                          href={content}
                          style={{
                            color: !!incoming ? "#1c3f73" : "white",
                            textDecoration: "underline"
                          }}
                          target="_blank"
                        >
                          <img
                            src={
                              !!incoming
                                ? "/images/down-blue.png"
                                : "/images/down-arrow.png"
                            }
                            style={{
                              height: 20,
                              width: 20,
                              marginRight: 5,
                              color: !!incoming ? "#1c3f73" : "white"
                            }}
                            alt=""
                          />{" "}
                          Download: {fileName}
                        </a>
                      </div>
                      <img
                        src={content}
                        style={{ width: "100%", marginTop: 10 }}
                        alt="upload_img"
                      />
                    </div>
                  )}
                  {(type === OPTIONS || type === INPUT || type === LEAVE) && (
                    <p className={classes.messageContent}>{content.title}</p>
                  )}
                  {type === FILE && (
                    <div className={classes.media}>
                      <a
                        rel="noopener noreferrer"
                        href={content}
                        style={{ color: !!incoming ? "#1c3f73" : "white" }}
                        target="_blank"
                      >
                        <img
                          src={
                            !!incoming
                              ? "/images/down-blue.png"
                              : "/images/down-arrow.png"
                          }
                          style={{
                            height: 20,
                            width: 20,
                            marginRight: 5,
                            color: !!incoming ? "#1c3f73" : "white"
                          }}
                          alt=""
                        />{" "}
                        Download: {fileName}
                      </a>
                    </div>
                  )}
                  {type === OPTIONS_RESPONSE && (
                    <p className={classes.messageContent}>{content.label}</p>
                  )}
                  {/* {type === RATING && role !== AGENT && (
                    <StarRating
                      readOnly={true}
                      value={Number(content)}
                      color={!!incoming ? "white" : "gray"}
                    />
                  )} */}
                </div>
              </div>
              {!incoming && type !== FEEDBACK && (
                <MyChatImage userId={userId} />
              )}
            </div>
            {type === OPTIONS && (
              <div
                className={classes.chipContainer}
                style={{
                  alignItems: !!incoming ? "flex-start" : "flex-end",
                  marginRight: !!incoming ? 0 : 35,
                  marginLeft: !!incoming ? 35 : 0
                }}
              >
                <MyChipSet
                  content={content}
                  incoming={incoming}
                  onOptionSelect={onOptionSelect}
                  roomStatus={roomStatus}
                  index={index}
                  lastIndex={lastIndex}
                />
              </div>
            )}
            {type === LEAVE && (
              <div
                className={classes.chipContainer}
                style={{
                  alignItems: !!incoming ? "flex-start" : "flex-end",
                  marginRight: !!incoming ? 0 : 35,
                  marginLeft: !!incoming ? 35 : 0
                }}
              >
                {index == lastIndex - 1 && (role != ADMIN && role != AGENT) ? (
                  <Chip
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid #4f1741",
                      color: "#4f1741",
                      margin: 2
                    }}
                    label={"Show Calender"}
                    // id={index}
                    onClick={() => this.setState({ open: true })}
                  />
                ) : (
                  <Chip
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid #4f1741",
                      color: "#4f1741",
                      margin: 2
                    }}
                    label={"Show Calender"}
                    // id={index}
                  />
                )}
              </div>
            )}
            {type === FEEDBACK &&
              role === ADMIN &&
              (content.role === USER ? (
                <Paper style={{ padding: 10, marginBottom: 20 }}>
                  <div
                    style={{
                      fontSize: 15,
                      padding: 20,
                      fontFamily: "OpenSans-SemiBold"
                    }}
                  >
                    User Feedback
                  </div>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                      }}
                    >
                      <div
                        style={{
                          fontSize: 15,
                          fontFamily: "OpenSans-Regular",
                          color: "#0076bb"
                        }}
                      >
                        Rating for Agent
                      </div>
                      <StarRating
                        readOnly={true}
                        value={Number(content.agentRating)}
                        color="gold"
                      />
                    </div>
                    <div
                      style={{
                        width: 1,
                        backgroundColor: "lightgray"
                      }}
                    />
                    <div
                      style={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                      }}
                    >
                      <div
                        style={{
                          fontSize: 15,
                          fontFamily: "OpenSans-Regular",
                          color: "#0076bb"
                        }}
                      >
                        Rating for Query
                      </div>
                      <StarRating
                        readOnly={true}
                        value={Number(content.queryRating)}
                        color="gold"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      paddingLeft: 20,
                      fontSize: 15,
                      paddingTop: 20,
                      fontFamily: "OpenSans-Regular",
                      color: "#0076bb"
                    }}
                  >
                    Comments
                  </div>
                  <p
                    style={{
                      paddingLeft: 20,
                      fontSize: 12,
                      paddingTop: 10,
                      fontFamily: "OpenSans-Regular"
                    }}
                  >
                    {content.comments}
                  </p>
                </Paper>
              ) : (
                <Paper style={{ padding: 10 }}>
                  <div
                    style={{
                      fontSize: 15,
                      padding: 20,
                      fontFamily: "OpenSans-SemiBold"
                    }}
                  >
                    Agent Feedback
                  </div>
                  <div
                    style={{
                      paddingLeft: 20,
                      fontSize: 15,
                      fontFamily: "OpenSans-Regular",
                      color: "#0076bb"
                    }}
                  >
                    Query
                  </div>
                  <div
                    style={{
                      fontSize: 12,
                      fontFamily: "OpenSans-Regular",
                      paddingTop: 10,
                      paddingLeft: 20
                    }}
                  >
                    {content.agentQuery}
                  </div>
                  <div
                    style={{
                      paddingLeft: 20,
                      paddingTop: 15,
                      fontSize: 15,
                      fontFamily: "OpenSans-Regular",
                      color: "#0076bb"
                    }}
                  >
                    Comments
                  </div>
                  <p
                    style={{
                      fontSize: 12,
                      fontFamily: "OpenSans-Regular",
                      paddingTop: 10,
                      paddingLeft: 20,
                      marginBottom: 20
                    }}
                  >
                    {content.agentComments}
                  </p>
                </Paper>
              ))}
            {type === BALANCE_LIST && (
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: !!incoming ? "100%" : "50%" }}>
                  <Paper>
                    <Table style={{ width: "100%", tableLayout: "fixed" }}>
                      <TableHead style={{ width: "100%" }}>
                        <TableRow style={{ width: "100%" }}>
                          <TableCell
                            style={{
                              padding: 0,
                              fontWeight: "bold",
                              color: "black",
                              paddingLeft: 10,
                              paddingRight: 10
                            }}
                            colSpan={3}
                          >
                            Scheme Name
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 0,
                              fontWeight: "bold",
                              color: "black",
                              paddingLeft: 10,
                              paddingRight: 10
                            }}
                            colSpan={1}
                          >
                            Balance Units
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 0,
                              fontWeight: "bold",
                              color: "black",
                              paddingLeft: 10,
                              paddingRight: 10
                            }}
                            colSpan={2}
                          >
                            Current Value
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {content.map((value, index) => (
                          <TableRow
                            key={index + value.schemedesc}
                            style={{ width: "100%" }}
                          >
                            <TableCell
                              // align="justified"
                              style={{
                                padding: 0,
                                paddingLeft: 10,
                                paddingRight: 10
                              }}
                              colSpan={3}
                            >
                              {value.schemedesc}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                padding: 0,
                                paddingLeft: 10,
                                paddingRight: 10
                              }}
                              colSpan={1}
                            >
                              {value.balunits}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                padding: 0,
                                paddingLeft: 10,
                                paddingRight: 10,
                                overflowWrap: "break-word"
                                // whiteSpace: "nowrap"
                              }}
                              colSpan={2}
                            >
                              {value.curamt.toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR"
                              })}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow style={{ width: "100%" }}>
                          <TableCell
                            // align="justified"
                            style={{
                              padding: 10,
                              fontWeight: "bold"
                            }}
                            colSpan={3}
                          >
                            Total
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              padding: 0
                              // padding: 10,
                            }}
                            colSpan={1}
                          />
                          <TableCell
                            align="right"
                            style={{
                              padding: 10,
                              fontWeight: "bold",
                              overflowWrap: "break-word",
                              whiteSpace: "nowrap"
                            }}
                            colSpan={2}
                          >
                            {content
                              .reduce((prev, curr) => {
                                return Number(curr.curamt) + prev;
                              }, 0)
                              .toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR"
                              })}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Paper>
                </div>
              </div>
            )}
            <div
              style={{
                display: type === FEEDBACK ? "none" : "",
                alignItems: !!incoming ? "flex-start" : "flex-end",
                marginRight: !!incoming ? 0 : 35,
                marginLeft: !!incoming ? 30 : 0
              }}
            >
              <MyTimeFormat incoming={incoming} createdAt={createdAt} />
            </div>
          </div>
        )}
        {type === LEAVE && (
          <Dialog
            open={this.state.open}
            // onClose={this.props.handleModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {/* <DialogContent> */}
            <DayPicker
              selectedDays={this.state.selectedDays}
              onDayClick={this.handleDayClick}
              canChangeMonth={false}
              disabledDays={[
                {
                  // after: new Date(2019, 7, 1)
                  before: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() + 1
                  )
                }
              ]}
            />
            {/* </DialogContent> */}
            <DialogActions>
              <MyChipSet
                content={content}
                incoming={incoming}
                onOptionSelect={data =>
                  onOptionSelect({
                    ...data,
                    selectedDays:
                      data.label == "Cancel"
                        ? []
                        : selectedDays.map(date => new Date(date).getTime())
                  })
                }
                roomStatus={roomStatus}
                closeModal={() => this.setState({ open: false })}
                index={index}
                lastIndex={lastIndex}
              />
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }
}

Message.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(messageStyles)(Message);
