import React from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";

export const CardView = function(props) {
  return (
    <Card className="pt-3 pb-3 mt-5 ">
      <div style={{ textAlign: "center", marginTop: 30 }}>
        <img
          src="/images/chatbot_icon_login.png"
          style={{ height: 100, width: 100 }}
          alt=""
        />
      </div>
      <CardContent>
        <h1 className="text-center heading">{props.heading}</h1>
      </CardContent>
      {props.children}
      <CardActions className="totalCard">
        <Button
          variant="outlined"
          onClick={props.submit}
          className="button-style"
          style={{
            background: "#0076bb",
            color: "white",
            paddingLeft: 40,
            paddingRight: 40,
            marginTop: 30,
            fontSize: 15,
            fontFamily: "OpenSans-SemiBold",
            borderWidth: 0
          }}
        >
          {props.buttonLabel}
        </Button>
      </CardActions>
      {/* <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          marginBottom: 60,
          marginTop: 20
        }}
      >
        <p
          style={{
            fontSize: 12,
            fontFamily: "OpenSans-SemiBold",
            marginBottom: 0
          }}
        >
          {" "}
          Don't Have a user Id?
        </p>
        <NavLink to={props.link}>
          <p
            style={{
              fontFamily: "OpenSans-SemiBold",
              marginBottom: 0,
              paddingLeft: 10,
              fontSize: 15
            }}
          >
            {props.icon}
          </p>
        </NavLink>
      </div> */}
    </Card>
  );
};
