export const RECONNECT = "reconnect";
export const CHAT_MESSAGE = "chat message";
export const HISTORY = "history";
export const UPDATES = "updates";
export const AGENT_JOIN = "agent join";
export const TYPING_STATUS = "typing status";
export const TYPING = "Typing....";
export const MESSAGE_ENTERED = "Message Entered";
export const FILE = "file";
export const IMAGE = "image";
export const TEXT = "text";
export const ACTIVE_CHATS = "ACTIVE_CHATS";
export const MY_CHATS = "MY_CHATS";
export const PENDING_CHATS = "PENDING_CHATS";
export const API_REQUEST = "api request";
export const API_RESPONSE = "api response";
export const AGENTS_ONLY = "agents only";
export const ADMINS_ONLY = "admins only";
export const TYPE_AGENT_RESOLVE = "agent resolve";
export const ROOM_RESOLVED = "room resolved";
export const DETAILS = "details";
export const AGENT_TRANSFER = "agent transfer";
export const DEPARTMENT = "department";
export const ROOM_TRANSFER = "room transfer";
export const DEPT_TRANSFER = "dept transfer";
export const RATING = "rating";
export const PING = "ping";
export const PONG = "pong";
export const OPTIONS = "options";
export const INPUT = "input";
export const BOT_MESSAGE = "bot message";
export const OPTIONS_RESPONSE = "user bot response";
export const BALANCE_LIST = "balance list";
export const NEW_CONVERSATION = "new conversation";
export const FEEDBACK = "feedback";
export const USER = "user";
export const FILTER_CHAT = "filter chat";
export const REAL_TIME_MONITORING = "real time monitoring";
export const TIMEOUT = "timeout";
export const CONTINUE_CHAT = "continue chat";
export const LEAVE = "leave";
export const SELECT_DRIVER = "select driver";
