import {
  GET_DEPARTMENTS_LIST_SUCCESS,
  CREATE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_SUCCESS,
  USER_LOGOUT_SUCCESS
} from "../types";

const initialState = {
  departments: []
};

export function departmentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DEPARTMENTS_LIST_SUCCESS: {
      const departments = action.docs;
      return { ...state, departments };
    }
    case CREATE_DEPARTMENT_SUCCESS: {
      let { newDepartment } = action;
      newDepartment = { ...newDepartment, agents: [] };
      return { ...state, departments: [newDepartment, ...state.departments] };
    }
    case UPDATE_DEPARTMENT_SUCCESS: {
      const { updated } = action;
      let { departments } = state;
      const departmentIndex = departments.findIndex(
        ({ _id }) => _id === updated._id
      );
      departments = [
        ...departments.slice(0, departmentIndex),
        updated,
        ...departments.slice(departmentIndex + 1)
      ];
      return { ...state, departments };
    }
    case DELETE_DEPARTMENT_SUCCESS: {
      const { _id: id } = action;
      let { departments } = state;
      departments = departments.filter(department => department._id !== id);
      return { ...state, departments };
    }
    case USER_LOGOUT_SUCCESS: {
      const state = initialState;
      return state;
    }
    default:
      return state;
  }
}
