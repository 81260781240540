import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import openSocket from "socket.io-client";
import Divider from "@material-ui/core/Divider";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TimeAgo from "react-timeago";
import { formatTime } from "./timeFormat";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import {
  IMAGE,
  OPTIONS,
  OPTIONS_RESPONSE,
  INPUT,
  FEEDBACK,
  SELECT_DRIVER,
  LEAVE
} from "../../../store/types/socket";
import { FILE } from "dns";
import Icon from "@material-ui/core/Icon";
import { AGENT, ADMIN } from "../../../store/types";
import { getDrivers } from "../../../store/actions";
import { FormControl, InputLabel, FilledInput } from "@material-ui/core";

const styles = theme => ({
  search: {
    Height: "50px",
    borderBottom: "1px solid lightgray",
    backgroundColor: "#FFF",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    alignItems: "Center",
    minHeight: 80
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    color: "white",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "white",
    width: "100%"
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "white",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },

  sidenav: {
    maxHeight: "100vh",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column"
  },
  chatbox: {
    display: "flex",
    alignItems: "center",
    paddingTop: 15,
    paddingRight: 20,
    paddingLeft: 20
  },
  title: {
    fontSize: 15,
    fontFamily: "OpenSans-Bold",
    paddingBottom: 5
  },
  chatmsg: {
    fontSize: 12,
    fontWeight: 400,
    fontFamily: "OpenSans-Regular",
    // color: "white",
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    paddingLeft: 15,
    paddingRight: 15,
    color: "gray"
  },
  row: {
    overflow: "scroll",
    flex: 1
    // maxHeight: "100vh"
    // marginTop: 5
  },
  totaltitlebox: {
    overflow: "hidden",
    width: "100%"
  },
  titleroom: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 15
  },
  Time: {
    fontSize: 8,
    alignSelf: "center",
    fontFamily: "OpenSans-Bold"
  },
  titlespace: {
    Height: "55px",
    borderBottom: "1px solid lightgray",
    backgroundColor: "#FFF",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    alignItems: "Center",
    minHeight: 55,
    fontWeight: 800,
    paddingLeft: 30,
    color: "#0076bb",
    fontFamily: "OpenSans-Bold"
  },
  avatar: {
    height: 40,
    width: 40,
    alignSelf: "center",
    flexShrink: 0
  },
  agenttotal: {
    display: "flex",
    alignItems: "center",
    padding: 15,
    paddingLeft: 70
  },
  agentdiv: {
    backgroundColor: "#f1f1f1",
    display: "inline-flex",
    borderRadius: 20,
    paddingRight: 60
  },
  agenticon: {
    height: 30,
    width: 30,
    // fontSize: 30,
    color: "#556080"
  },
  agentname: {
    marginLeft: 10,
    fontSize: 15,
    fontWeight: 400,
    color: "#565b63",
    fontFamily: "OpenSans-Regular",
    alignSelf: "center"
  }
});

class ConversationsList extends Component {
  constructor(props) {
    super(props);
    const { token } = this.props;
    this.socket = openSocket("/", {
      query: {
        token
      }
    });
    this.state = {
      filteredChat: 2,
      filterMenu: [
        {
          label: "Active Chats",
          value: 2
        },
        {
          label: "My Chats",
          value: 6
        },
        { label: "Pending Chats", value: 5 }
      ],
      drivers: []
    };
  }

  componentDidMount() {
    this.props.getDrivers();
  }

  componentWillReceiveProps(newProps) {
    const { drivers = [] } = newProps;
    // console.log(drivers, "drivers");
    this.setState({ drivers });
  }

  handleDriverSelect = () => event => {
    this.socket.emit(SELECT_DRIVER, event.target.value);
    this.setState({
      driver: ""
    });
  };

  handleChange = event => {
    const filteredChat = event.target.value;
    this.setState({ filteredChat });
    this.props.filterChats(filteredChat);
  };

  render() {
    const { classes, selectedChat, role, agent } = this.props;
    const { filteredChat, filterMenu } = this.state;
    return (
      <div className={classes.sidenav}>
        <div className={classes.titlespace}>DRIVERZZ CHAT</div>
        <div className={classes.search}>
          {/* <Select
            value={filteredChat}
            onChange={this.handleChange}
            input={<OutlinedInput labelWidth={0} />}
            style={{ width: "50%", backgroundColor: "#f8f8f8" }}
          >
            {filterMenu.map((menuItem, i) => (
              <MenuItem value={menuItem.value} key={i}>
                {menuItem.label}
              </MenuItem>
            ))}
          </Select> */}
          <Select
            value={this.state.driver || ""}
            onChange={this.handleDriverSelect()}
            style={{ width: "50%", marginLeft: 20 }}
            displayEmpty
            input={<OutlinedInput labelWidth={0} />}
          >
            <MenuItem value={""}>Select driver</MenuItem>
            {this.state.drivers.map(ele => (
              <MenuItem value={ele._id} key={ele._id}>
                {ele.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={classes.row}>
          {/* <FormControl variant="filled" className={classes.formControl}> */}

          {/* </FormControl> */}
          {this.props.updates.map(message => {
            let {
              type,
              fileName,
              content,
              createdAt,
              roomId,
              agentId
            } = message;
            if (
              type === OPTIONS ||
              type === OPTIONS_RESPONSE ||
              type === INPUT ||
              type == LEAVE
            ) {
              content = JSON.parse(content);
            }
            return (
              <div
                key={roomId._id}
                style={{
                  backgroundColor:
                    roomId._id === selectedChat._id ? "#eff6fa" : "white"
                }}
                onClick={_ => this.props.onSelectChat(message)}
              >
                <div
                  className={classes.chatbox}
                  style={{ paddingBottom: !!agentId ? 0 : 10 }}
                >
                  <img
                    src="/images/guest_icon.png"
                    className={classes.avatar}
                    alt=""
                  />
                  <div className={classes.totaltitlebox}>
                    <div className={classes.titleroom}>
                      <div className={classes.title}>{roomId.title}</div>
                      <div className={classes.Time}>
                        <TimeAgo
                          date={createdAt}
                          formatter={formatTime}
                          className={classes.Time}
                        />
                      </div>
                    </div>
                    <div className={classes.chatmsg}>
                      {type === FILE || type === IMAGE
                        ? `File: ${fileName}`
                        : type === FEEDBACK
                        ? role === AGENT || role === ADMIN
                          ? "Resolved"
                          : `Rating: ${content}`
                        : type === OPTIONS || type === INPUT || type == LEAVE
                        ? content.title
                        : type === OPTIONS_RESPONSE
                        ? content.label
                        : content}
                    </div>
                  </div>
                </div>
                {!!agentId && (
                  <div className={classes.agenttotal}>
                    <div className={classes.agentdiv}>
                      {/* <img
                        src={
                          message.userId.name === "Bot"
                            ? "/images/chatbot_icon.png"
                            : "/images/user_icon.png"
                        }
                        className={classes.agenticon}
                      /> */}
                      {message.agentId.name === "Bot" ? (
                        <img
                          src={"/images/chatbot_icon.png"}
                          className={classes.agenticon}
                          alt=""
                        />
                      ) : (
                        <Icon style={{ fontSize: 35, color: "#546080" }}>
                          account_circle
                        </Icon>
                      )}

                      <div className={classes.agentname}>{agentId.name}</div>
                    </div>
                  </div>
                )}
                <Divider />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.socket.removeAllListeners();
  }
}

ConversationsList.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(
  connect(
    ({ operatorReducer: { drivers }, authReducer: { token } }) => ({
      drivers,
      token
    }),
    { getDrivers }
  )(ConversationsList)
);
