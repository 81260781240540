import React from "react";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

export function UpdateIconButton(props) {
  const { color, onClick, image } = props;
  return (
    <div
      style={{
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: color,
        marginRight: 10,
        marginLeft: 10
      }}
      onClick={onClick}
    >
      <img
        alt="update icon"
        src={image}
        style={{
          width: 20,
          height: 20
        }}
      />
    </div>
  );
}

export function CreateButton(props) {
  const { onClick } = props;
  return (
    <div style={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
      <Button
        className="button-style"
        variant="outlined"
        onClick={onClick}
        style={{
          backgroundColor: "#0076bb",
          color: "white",
          borderColor: "#0076bb",
          fontSize: 15,
          fontFamily: "OpenSans-Regular",
          paddingLeft: 20,
          paddingRight: 20
        }}
      >
        <Icon style={{ marginRight: 10 }}>add</Icon>
        Create New
      </Button>
    </div>
  );
}

export function Heading(props) {
  const { header } = props;
  return (
    <div
      className="d-flex justify-content-center "
      style={{
        flexGrow: 1,
        fontFamily: "OpenSans-SemiBold",
        fontSize: 18
      }}
    >
      {header}
    </div>
  );
}

export function CommonHeader(props) {
  const { header, onClick } = props;
  return (
    <div style={{ display: "flex", marginTop: 50, marginBottom: 30 }}>
      <div style={{ flexGrow: 1 }} />
      <Heading header={header} />
      <CreateButton onClick={onClick} />
    </div>
  );
}
