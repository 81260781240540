import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList
} from "recharts";

export default class Charts extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      colors: ["#7964D3", "#FFA645", "#3AB5CA", "#1F3BB3", "#36A0FF", "#B3DFC3"]
    };
  }

  render() {
    const { colors } = this.state;
    const { data } = this.props;
    // const {active_chats, agents_online, bot_chats, pending_chats, resolved_chats, users_online} = action
    return (
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="att" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="active_chats"
          fill={colors[0]}
          barSize={30}
          name="Active Chats"
        >
          <LabelList dataKey="active_chats" position="top" />
        </Bar>
        <Bar
          dataKey="agents_online"
          fill={colors[1]}
          barSize={30}
          name="Agents Online"
        >
          <LabelList dataKey="agents_online" position="top" />
        </Bar>
        <Bar dataKey="bot_chats" fill={colors[2]} barSize={30} name="Bot Chats">
          <LabelList dataKey="bot_chats" position="top" />
        </Bar>
        <Bar
          dataKey="pending_chats"
          fill={colors[3]}
          barSize={30}
          name="Pending Chats"
        >
          <LabelList dataKey="pending_chats" position="top" />
        </Bar>
        <Bar
          dataKey="resolved_chats"
          fill={colors[4]}
          barSize={30}
          name="Resolved Chats"
        >
          <LabelList dataKey="resolved_chats" position="top" />
        </Bar>
        {/* <Bar
          dataKey="users_online"
          fill={colors[5]}
          barSize={30}
          name="Users Online"
        >
          <LabelList dataKey="users_online" position="top" />
        </Bar> */}
      </BarChart>
    );
  }
}
