import { CALL_API } from "../api.middleware";
import {
  GET_OPERATORS,
  GET_OPERATORS_SUCCESS,
  GET_OPERATORS_FAILED,
  CREATE_OPERATOR,
  CREATE_OPERATOR_SUCCESS,
  CREATE_OPERATOR_FAILED,
  UPDATE_OPERATOR,
  UPDATE_OPERATOR_SUCCESS,
  UPDATE_OPERATOR_FAILED,
  DELETE_OPERATOR,
  DELETE_OPERATOR_SUCCESS,
  DELETE_OPERATOR_FAILED,
  GET_DRIVERS,
  GET_DRIVERS_SUCCESS,
  GET_DRIVERS_FAILURE
} from "../types";
import { dispatch } from "rxjs/internal/observable/pairs";

export const getOperators = () => async dispatch => {
  try {
    await dispatch({
      [CALL_API]: {
        url: "/department/agents/",
        method: "GET",
        types: [GET_OPERATORS, GET_OPERATORS_SUCCESS, GET_OPERATORS_FAILED]
      }
    });
  } catch (error) {
    alert(error);
  }
};

export const createOperator = payload => async dispatch => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: "/users/create_operator",
        method: "POST",
        body: payload,
        types: [
          CREATE_OPERATOR,
          CREATE_OPERATOR_SUCCESS,
          CREATE_OPERATOR_FAILED
        ]
      }
    });
  } catch (error) {
    alert(error);
  }
};

export const getOperator = id => async dispatch => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/users/get_operator_detail`,
        method: "GET",
        params: { agentid: id }
      }
    });
  } catch (error) {
    alert(error);
  }
};

export const updateOperator = (payload, id) => async dispatch => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/users/edit/${id}`,
        method: "POST",
        body: payload,
        types: [
          UPDATE_OPERATOR,
          UPDATE_OPERATOR_SUCCESS,
          UPDATE_OPERATOR_FAILED
        ]
      }
    });
  } catch (error) {
    alert(error);
  }
};

export const deleteOperator = id => async dispatch => {
  try {
    await dispatch({
      [CALL_API]: {
        url: "/users/delete_operator",
        method: "GET",
        params: { agentid: id },
        types: [
          DELETE_OPERATOR,
          DELETE_OPERATOR_SUCCESS,
          DELETE_OPERATOR_FAILED
        ]
      }
    });
  } catch (error) {
    alert(error);
  }
};

export const getDrivers = () => async dispatch => {
  try {
    await dispatch({
      [CALL_API]: {
        url: "/chat_auth/drivers",
        method: "GET",
        types: [GET_DRIVERS, GET_DRIVERS_SUCCESS, GET_DRIVERS_FAILURE]
      }
    });
  } catch (error) {
    alert(error);
  }
};
