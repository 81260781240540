import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import DepartmentRouter from "./departments/router";
import OperatorRouter from "./operators/router";
import ChatRouter from "./chat/router";
import SettingsRouter from "./settings/router";
import FaqRouter from "./faqs/router";
import Home from "./home";
import { ADMIN } from "../../store/types";
export default class AppRouter extends Component {
  render() {
    const { match, role } = this.props;
    const { path } = match;
    return (
      <div>
        <Switch>
          <Route path={`${path}/departments`} component={DepartmentRouter} />
          <Route path={`${path}/operators`} component={OperatorRouter} />
          <Route path={`${path}/home`} exact component={Home} />
          <Route path={`${path}/conversations`} component={ChatRouter} />
          <Route path={`${path}/settings`} component={SettingsRouter} />
          <Route path={`${path}/faqs`} component={FaqRouter} />
          <Route
            path={`${path}`}
            render={props => (
              <Redirect
                to={{
                  pathname:
                    role === ADMIN ? `${path}/home` : `${path}/conversations`
                }}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}
