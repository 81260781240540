import { CALL_API } from "../api.middleware";
import {
  GET_FAQS,
  GET_FAQS_SUCCESS,
  GET_FAQS_FAILED,
  DELETE_FAQ,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_FAILED,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILED,
  CREATE_CATEGORY,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAILED
} from "../types/faqs";

export const getFaqs = (page = 1, limit = 10) => async dispatch => {
  try {
    await dispatch({
      [CALL_API]: {
        url: "/FAQ/getFaqs",
        method: "GET",
        types: [GET_FAQS, GET_FAQS_SUCCESS, GET_FAQS_FAILED],
        params: { page, limit }
      }
    });
  } catch (error) {
    alert(error);
  }
};

export const createFaq = payload => async dispatch => {
  try {
    await dispatch({
      [CALL_API]: {
        url: "/FAQ/create-article",
        method: "POST",
        body: payload
      }
    });
  } catch (error) {
    alert(error);
  }
};

export const editFaq = (payload, id) => async dispatch => {
  try {
    await dispatch({
      [CALL_API]: {
        url: `/FAQ/${id}/edit-FAQ`,
        method: "POST",
        body: payload
      }
    });
  } catch (error) {
    alert(error);
  }
};

export const getFaqDetail = id => async dispatch => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/FAQ/${id}/category-detail`,
        method: "GET"
      }
    });
  } catch (error) {
    alert(error);
  }
};

export const deleteFaq = id => async dispatch => {
  try {
    await dispatch({
      [CALL_API]: {
        url: `/FAQ/${id}/remove-faq`,
        method: "POST",
        types: [DELETE_FAQ, DELETE_FAQ_SUCCESS, DELETE_FAQ_FAILED]
      }
    });
  } catch (error) {
    alert(error);
  }
};

export const getCategories = () => async dispatch => {
  try {
    await dispatch({
      [CALL_API]: {
        url: "/FAQ/get-categories",
        method: "GET",
        types: [GET_CATEGORIES, GET_CATEGORIES_SUCCESS, GET_CATEGORIES_FAILED]
      }
    });
  } catch (error) {
    alert(error);
  }
};

export const getDeptFaqs = id => async dispatch => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/FAQ/${id}/get-dept-categorys`,
        method: "GET"
        // types: [GET_DEPT_FAQS, GET_DEPT_FAQS_SUCCESS, GET_DEPT_FAQS_FAILURE]
      }
    });
  } catch (error) {
    alert(error);
  }
};

export const createCategory = payload => async dispatch => {
  try {
    await dispatch({
      [CALL_API]: {
        url: "/FAQ/create-category",
        method: "POST",
        body: payload,
        types: [
          CREATE_CATEGORY,
          CREATE_CATEGORY_SUCCESS,
          CREATE_CATEGORY_FAILED
        ]
      }
    });
  } catch (error) {
    alert(error);
  }
};

export const incrementFaqViews = id => async dispatch => {
  try {
    await dispatch({
      [CALL_API]: {
        url: `/FAQ/${id}/increment-views`,
        method: "POST"
      }
    });
  } catch (error) {
    alert(error);
  }
};
