import { CALL_API } from "../api.middleware";
import {
  GET_SETTINGS_DATA,
  GET_SETTINGS_DATA_SUCCESS,
  GET_SETTINGS_DATA_FAILED
} from "../types";

export const getSettingsData = () => async dispatch => {
  try {
    await dispatch({
      [CALL_API]: {
        url: "/constants/get_site_constants",
        types: [
          GET_SETTINGS_DATA,
          GET_SETTINGS_DATA_SUCCESS,
          GET_SETTINGS_DATA_FAILED
        ],
        method: "GET"
      }
    });
  } catch (error) {
    alert(error);
  }
};

export const setBusinessHours = (payload, id) => async dispatch => {
  try {
    await dispatch({
      [CALL_API]: {
        url: `/constants/set_site_constants`,
        types: [
          GET_SETTINGS_DATA,
          GET_SETTINGS_DATA_SUCCESS,
          GET_SETTINGS_DATA_FAILED
        ],
        params: { id },
        body: payload,
        method: "POST"
      }
    });
  } catch (error) {
    alert(error);
  }
};
