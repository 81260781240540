import React, { Component } from "react";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { adminStyles } from "./chatStyles";
import Button from "@material-ui/core/Button";
import moment from "moment";
import classNames from "classnames";
import { ADMIN } from "../../../store/types";
class UserInfo extends Component {
  chatItem = chat => {
    const { classes, selectChat, selectedChat } = this.props;
    return (
      <div
        className={classes.pastinner}
        style={{ cursor: selectedChat._id === chat._id ? "auto" : "pointer" }}
        // key={index}
        onClick={() =>
          chat._id === selectedChat._id
            ? console.log("hi")
            : selectChat({
                roomId: { title: chat.title, _id: chat._id },
                agentId: chat.agent
              })
        }
      >
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <div className={classes.dot}>
            <img
              alt="history icon"
              src="/images/history_icon.png"
              style={{ height: 18, width: 18 }}
            />
          </div>
          <div
            className={classes.pastchatsDate}
            style={{
              color: selectedChat._id === chat._id ? "#000" : "#0076bb"
            }}
          >
            {moment(new Date(chat.createdAt)).format("DD/MM/YYYY h:mm:ss a")}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      classes,
      info,
      pastChats = [],
      userStatus,
      userDetails,
      role
    } = this.props;
    let { city, ipAddress = "", device } = info || {};
    const { name, email, phoneNo } = userDetails || {};
    ipAddress =
      role === ADMIN
        ? ipAddress
        : [...ipAddress.split(".").slice(0, 2), "XXX", "XXX"].join(".");
    return (
      <React.Fragment>
        <div className={classes.lasttitlediv}>
          <div className={classes.titlebox}>
            <div className={classes.guestitle}>
              {pastChats.length > 0 ? name || pastChats[0].title : ""}
            </div>
            <div className={classes.totaloffline}>
              <img
                alt="online icon"
                src={
                  userStatus === "online"
                    ? "/images/online_icon.png"
                    : "/images/red_icon.png"
                }
                style={{ width: 15, height: 15 }}
              />
              <div
                className={
                  userStatus === "online" ? classes.online : classes.offline
                }
              >
                {userStatus}
              </div>
            </div>
          </div>
          {/* <div className={classes.icondiv}>
            <Icon className={classes.infoicon}>phone_iphone</Icon>
            <div className={classes.icontext}>+91 7569771196</div>
          </div> */}
          {/* <div className={classes.icondiv}>
            <Icon className={classes.infoicon}>place</Icon>
            <div className={classes.icontext}>{city}</div>
          </div> */}
          {/* <div className={classes.icondiv}>
            <Icon className={classes.infoicon}>wifi</Icon>
            <div className={classes.icontext}>{ipAddress}</div>
          </div>
          <div className={classes.icondiv}>
            <Icon className={classes.infoicon}>desktop_windows</Icon>
            <div className={classes.icontext}>{device}</div>
          </div> */}
        </div>
        <div className={classes.lastbuttondiv}>
          {/* <Button
            variant="contained"
            size="small"
            style={{ background: "#01b8ef" }}
            className={classes.button}
          >
            <Icon className={classes.buttonicon}>add_circle</Icon>
            Edit
          </Button> */}
          {/* <Button
            size="small"
            className={classNames(classes.button, "button-outline")}
          >
            <Icon className={classes.buttonicon}>cancel</Icon>
            Block
          </Button> */}
        </div>
        <Divider className={classes.divider} />

        <div className={classes.customfields}>CUSTOM FIELDS</div>
        <div style={{ marginBottom: 20 }}>
          <div className={classes.accesstokendiv}>
            <div className={classes.tokenlabel}>Name :</div>
            <div className={classes.tokensublabel}>
              {name || "Anonymous User"}
            </div>
          </div>
          <div className={classes.accesstokendiv}>
            <div className={classes.tokenlabel}>E mail :</div>
            <div className={classes.tokensublabel}>{email || ""}</div>
          </div>
          <div className={classes.accesstokendiv}>
            <div className={classes.tokenlabel}>Phone</div>
            <div className={classes.tokensublabel}>{phoneNo || ""}</div>
          </div>
        </div>
        <Divider className={classes.divider} />
        {pastChats.length > 0 && (
          <div>
            <div className={classes.pastchats}>Active Chat</div>
            <React.Fragment>{this.chatItem(pastChats[0])}</React.Fragment>
            <div className={classes.pastchats}>Chat History</div>
            {pastChats
              // .reverse()
              .slice(1)
              .map((chat, index) => (
                <React.Fragment key={index}>
                  {this.chatItem(chat)}
                </React.Fragment>
              ))}
          </div>
        )}
      </React.Fragment>
    );
  }
}

UserInfo.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(adminStyles)(UserInfo);
