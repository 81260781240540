export const GET_DEPARTMENTS_LIST = "GET_DEPARTMENTS_LIST";
export const GET_DEPARTMENTS_LIST_SUCCESS = "GET_DEPARTMENTS_LIST_SUCCESS";
export const GET_DEPARTMENTS_LIST_FAILED = "GET_DEPARTMENTS_LIST_FAILED";
export const CREATE_DEPARTMENT = "CREATE_DEPARTMENT";
export const CREATE_DEPARTMENT_SUCCESS = "CREATE_DEPARTMENT_SUCCESS";
export const CREATE_DEPARTMENT_FAILED = "CREATE_DEPARTMENT_FAILED";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_FAILED = "UPDATE_DEPARTMENT_FAILED";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_FAILED = "DELETE_DEPARTMENT_FAILED";
export const COPY = "Copy to clipboard";
export const COPIED = "Copied to clipboard";
