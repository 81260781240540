import {
  DELETE_FAQ_SUCCESS,
  GET_FAQS_SUCCESS,
  GET_CATEGORIES_SUCCESS,
  CREATE_CATEGORY_SUCCESS
} from "../types/faqs";

export function faqReducer(
  state = { categories: [], faqs: [], page: 1, pages: 0 },
  action
) {
  switch (action.type) {
    case DELETE_FAQ_SUCCESS: {
      const { deletedFAQ } = action;
      const faqs = state.faqs.filter(faq => faq._id !== deletedFAQ);
      return { ...state, faqs };
    }
    case GET_FAQS_SUCCESS: {
      const { docs = [], page, pages, total } = action;
      return { ...state, faqs: docs, page, pages, total };
    }
    case GET_CATEGORIES_SUCCESS: {
      const { categories = [] } = action;
      return { ...state, categories };
    }
    case CREATE_CATEGORY_SUCCESS: {
      const { createdCategory } = action;
      const categories = [createdCategory, ...state.categories];
      return { ...state, categories };
    }
    default:
      return state;
  }
}
