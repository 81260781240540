import { USER_LOGOUT_SUCCESS } from "./types";

export const BASE_URL = "/api";
const INVALID_USER = "Invalid user";
const DEFAULT_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

async function invokeAPI(endpoint, token, config) {
  const headers = token
    ? { ...DEFAULT_HEADERS, Authorization: `Bearer ${token}` }
    : { ...DEFAULT_HEADERS };
  const updatedConfig = { ...config, headers };
  const response = await fetch(BASE_URL + endpoint, updatedConfig);
  const body = await response.json();
  if (response.status >= 500) {
    throw new Error("Something went wrong");
  }
  if (response.status >= 400) {
    //TODO: Fix this error so we can send errors from API directly.
    throw new Error(
      body.errors.map(error => Object.values(error))[0] ||
        "Something went wrong"
    );
  }
  return body;
}

export const CALL_API = Symbol("Call API");

export default store => next => async action => {
  // So the middleware doesn't get applied to every single action
  if (typeof action[CALL_API] === "undefined") {
    return next(action);
  }

  let {
    url,
    method,
    types = [],
    showLoader = false,
    body = undefined,
    params
  } = action[CALL_API];
  // if (body) {
  //   method = "POST";
  // }
  const [requestType, successType] = types;

  const { authReducer } = store.getState();
  requestType && next({ type: requestType });
  try {
    if (showLoader) {
      //TODO: Dispatch show modal loader now.
    }
    const queryParams = new URLSearchParams();
    for (let param in params) {
      if (params[param]) {
        queryParams.set(param, params[param]);
      }
    }

    const responseBody = await invokeAPI(
      url + "?" + queryParams.toString(),
      authReducer.token,
      { method, body: JSON.stringify(body) }
    );

    successType &&
      next({
        ...responseBody,
        type: successType
      });

    return responseBody;
  } catch (error) {
    if (error.message === INVALID_USER) {
      next({ type: USER_LOGOUT_SUCCESS });
      return;
    }
    throw error;
  } finally {
    if (showLoader) {
      //TODO: Dispatch hide modal loader now.
    }
  }
};
