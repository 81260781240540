import { GET_REPORTS_DATA_SUCCESS, TODAY } from "../types/reports";

const initialState = {
  data: {
    active_chats: 0,
    agents_online: 0,
    bot_chats: 0,
    pending_chats: 0,
    resolved_chats: 0,
    users_online: 0,
    att: TODAY
  }
};
export function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REPORTS_DATA_SUCCESS: {
      const { type, ...others } = action;
      // const {active_chats, agents_online, bot_chats, pending_chats, resolved_chats, users_online} = action
      return {
        ...state,
        data: { ...others, att: others.att.split("_").join(" ") }
      };
    }
    default:
      return state;
  }
}
