import React from "react";
import { Route } from "react-router";
import FaqList from "./list";
import CreateFaq from "./create";
import ModalSwitch from "../../../modalRoute";

export default function FaqRouter(props) {
  const { location, history } = props;
  return (
    <ModalSwitch location={location} history={history}>
      <Route path="/dashboard/faqs/create" component={CreateFaq} />
      <Route path="/dashboard/faqs/:faqId/edit" component={CreateFaq} />
      <Route path="/dashboard/faqs" component={FaqList} />
    </ModalSwitch>
  );
}
