import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { loadCSS } from "fg-loadcss/src/loadCSS";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { chatStyles } from "./chatStyles";
import { DEPARTMENT, RATING } from "../../../store/types/socket";
import { AGENT, ADMIN } from "../../../store/types";
import CommonChat from "./commonChat";
import Select from "@material-ui/core/Select";
import DialogActions from "@material-ui/core/DialogActions";
import { getDepartmentsList, getOperators } from "../../../store/actions";
import Input from "@material-ui/core/Input";
import moment from "moment";
import classNames from "classnames";
class ChatPane extends Component {
  constructor(props) {
    super(props);
    const { departments, agents } = this.props;
    this.state = {
      open: false,
      openMenu: false,
      scroll: "paper",
      departments,
      agents,
      searchText: "",
      agentDialogOpen: false,
      agentQuery: "",
      agentComments: "",
      endChatMenu: [
        // { value: 90, label: "End in 90 seconds" },
        // { value: 60, label: "End in 60 seconds" },
        // { value: 45, label: "End in 45 seconds" },
        // { value: 30, label: "End in 30 seconds" },
        { value: 0, label: "End immediately" }
      ]
    };
    this.onSelect = this.onSelect.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { departments, agents } = nextProps;
    this.setState({
      departments,
      agents
    });
  }

  handleToggle = () => {
    this.props.getDepartmentsList();
    this.props.getOperators();
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onSelect = (id, type) => () => {
    this.props.transferChat(id, type);
    this.handleClose();
  };

  searchItems = event => {
    const query = event.target.value.trim().toLowerCase();
    const { searchText } = this.state;
    if (query === searchText) {
      return;
    }
    if (query === "") {
      this.setState({
        searchText: query,
        departments: this.props.departments,
        agents: this.props.agents
      });
      return;
    }

    this.setState(({ searchText = "", departments, agents }) => ({
      searchText: query,
      departments: (searchText && query.startsWith(searchText)
        ? departments
        : this.props.departments
      ).filter(({ name }) => name && name.toLowerCase().includes(query)),
      agents: (searchText && query.startsWith(searchText)
        ? agents
        : this.props.agents
      ).filter(({ name }) => name && name.toLowerCase().includes(query))
    }));
  };

  componentDidMount() {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.1.0/css/all.css",
      document.querySelector("#insertion-point-jss")
    );
  }

  handleModalClose = () => {
    this.setState({
      agentDialogOpen: false,
      agentComments: "",
      agentQuery: ""
    });
  };

  handleChangeItem = item => () => {
    if (!!item.value) {
      this.props.endChatTime(item.value);
      this.setState({ openMenu: false });
    } else {
      this.props.endConversation();
      this.setState({ openMenu: false });
    }
  };

  closeMenu = () => {
    this.setState({ openMenu: false });
  };

  render() {
    let {
      departments,
      agents,
      agentQuery,
      agentComments,
      endChatMenu,
      openMenu
    } = this.state;
    let {
      classes,
      roomStatus,
      resolveChat,
      role,
      user,
      selectedAgent,
      messages,
      title = "Karvy Support",
      imageUrl,
      showHeader,
      typing,
      userDetails,
      chatTitle,
      agentRated,
      time
    } = this.props;
    if (role === AGENT) {
      agents = agents.filter(agent => agent._id !== user);
    }
    messages = messages.filter(
      message => !(message.type === RATING && role === AGENT)
    );
    return (
      <React.Fragment>
        {showHeader ? (
          <div className={classes.chatroom}>
            <div className={classes.header}>
              {(roomStatus < 3 || roomStatus === 5) &&
              ((!!selectedAgent && selectedAgent._id === user) ||
                role === ADMIN) ? (
                <Fragment>
                  <Button
                    className="button-style"
                    variant="outlined"
                    style={{ border: "1px solid white", color: "white" }}
                    onClick={this.handleToggle}
                  >
                    Transfer Chat
                  </Button>
                  <div style={{ color: "white" }}>
                    {roomStatus === 5 ? "Pending Chat" : ""}
                  </div>
                </Fragment>
              ) : (
                <React.Fragment>
                  {!!chatTitle ? (
                    <div
                      style={{ color: "#FFF", fontFamily: "OpenSans-SemiBold" }}
                    >
                      {moment(new Date(chatTitle.createdAt)).format(
                        "DD/MM/YYYY h:mm:ss a"
                      )}
                    </div>
                  ) : (
                    <div />
                  )}
                </React.Fragment>
              )}
              {(roomStatus < 3 || roomStatus === 5) &&
                ((!!selectedAgent && selectedAgent._id === user) ||
                  role === ADMIN) && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Button
                      className="button-style"
                      variant="outlined"
                      style={{
                        border: "1px solid white",
                        color: "white"
                      }}
                      onClick={() => this.setState({ openMenu: !openMenu })}
                      // onClick={() => this.setState({ agentDialogOpen: true })}
                    >
                      End Chat
                    </Button>
                    {!!time && <div>Chat will end in {time} seconds</div>}
                  </div>
                )}
            </div>

            <CommonChat
              messages={messages}
              roomStatus={roomStatus}
              selectedAgent={selectedAgent}
              user={user}
              role={role}
              onChange={this.props.onChange}
              submitMessage={this.props.submitMessage}
              socket={this.props.socket}
              imageUrl={imageUrl}
              typing={typing}
              userDetails={userDetails}
            />
          </div>
        ) : (
          <div className={classes.titled}>{title}</div>
        )}

        <Dialog
          open={openMenu}
          onClose={this.closeMenu}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
          style={{ maxHeight: 500 }}
        >
          {endChatMenu.map((menuItem, index) => (
            <MenuItem onClick={this.handleChangeItem(menuItem)} key={index}>
              {menuItem.label}
            </MenuItem>
          ))}
        </Dialog>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
          style={{ maxHeight: 500 }}
        >
          <DialogContent>
            <TextField
              label="Search here"
              value={this.state.searchText}
              onChange={this.searchItems}
            />
            {/* {departments.length > 0 && (
              <div>
                <div>Departments</div>
                <MenuList>
                  {departments.map((department, i) => (
                    <MenuItem
                      key={i}
                      onClick={this.onSelect(department._id, DEPARTMENT)}
                    >
                      {department.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </div>
            )} */}
            {agents.length > 0 && (
              <div>
                <div>Operators</div>
                <MenuList>
                  {agents.map((agent, i) => (
                    <MenuItem key={i} onClick={this.onSelect(agent._id, AGENT)}>
                      {agent.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </div>
            )}
          </DialogContent>
        </Dialog>
        <Dialog
          open={(!agentRated && roomStatus === 3) || this.state.agentDialogOpen}
          onClose={this.handleModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <div
              style={{
                backgroundColor: "#f2f8fb",
                padding: 10,
                borderRadius: 5
              }}
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    fontSize: 15,
                    fontFamily: "OpenSans-Regular",
                    width: "40%",
                    display: "flex",
                    justifyContent: "flex-end"
                  }}
                >
                  Customer name :
                </div>
                <div
                  style={{
                    fontSize: 15,
                    fontFamily: "OpenSans-Regular",
                    marginLeft: 15
                  }}
                >
                  {userDetails.name}
                </div>
              </div>
              <div>
                <div
                  style={{
                    fontSize: 15,
                    fontFamily: "OpenSans-Regular",
                    width: "40%",
                    display: "flex",
                    justifyContent: "flex-end"
                  }}
                >
                  Email :
                </div>
                <div
                  style={{
                    fontSize: 15,
                    fontFamily: "OpenSans-Regular",
                    marginLeft: 15
                  }}
                >
                  {userDetails.email}
                </div>
              </div>
              <div>
                <div
                  style={{
                    fontSize: 15,
                    fontFamily: "OpenSans-Regular",
                    width: "40%",
                    display: "flex",
                    justifyContent: "flex-end"
                  }}
                >
                  Phone :
                </div>
                <div
                  style={{
                    fontSize: 15,
                    fontFamily: "OpenSans-Regular",
                    marginLeft: 15
                  }}
                >
                  {userDetails.phoneNo}
                </div>
              </div>
            </div>
            <div
              style={{
                fontSize: 15,
                fontFamily: "OpenSans-SemiBold",
                color: "#0076bb",
                paddingLeft: 5,
                paddingTop: 20
              }}
            >
              What was the type of query / issue Customer was facing ?
            </div>
            <Select
              value={this.state.agentQuery}
              onChange={event =>
                this.setState({ agentQuery: event.target.value })
              }
              inputProps={{
                name: "Account Balance",
                id: "age-simple"
              }}
              style={{
                width: "100%",
                backgroundColor: "transparent",
                paddingTop: 20
              }}
            >
              <MenuItem value="Account Balance">Account Balance</MenuItem>
              <MenuItem value="Change Address">Change Address</MenuItem>
              <MenuItem value="Change Phone Number">
                Change Phone Number
              </MenuItem>
              <MenuItem value="Issue with Fund Balance">
                Issue with Fund Balance
              </MenuItem>
            </Select>
            <div
              style={{
                fontSize: 15,
                fontFamily: "OpenSans-SemiBold",
                color: "#0076bb",
                paddingLeft: 5,
                paddingTop: 20
              }}
            >
              Add issue details below{" "}
            </div>
            <Input
              onChange={event =>
                this.setState({ agentComments: event.target.value })
              }
              value={agentComments}
              placeholder="Type Here"
              style={{
                fontSize: 12,
                width: "100%",
                paddingTop: 20,
                paddingBottom: 20
              }}
            />
          </DialogContent>
          <DialogActions style={{ justifyContent: "space-between" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              {!agentRated && roomStatus === 3 ? (
                <div />
              ) : (
                <Button
                  onClick={() => this.handleModalClose()}
                  className={classNames(
                    classes.dialogsumbitbutton,
                    "button-outline"
                  )}

                  // autoFocus
                >
                  Cancel
                </Button>
              )}
              <Button
                onClick={() => {
                  resolveChat({
                    agentComments,
                    agentQuery
                  });
                  this.handleModalClose();
                }}
                className={classNames(
                  classes.dialogsumbitbutton,
                  "button-outline"
                )}
                // autoFocus
              >
                submit
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

ChatPane.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(chatStyles)(
  connect(
    ({ departmentReducer, operatorReducer }) => {
      const { departments = [] } = departmentReducer;
      const { agents = [] } = operatorReducer;
      return { departments, agents };
    },
    { getOperators, getDepartmentsList }
  )(ChatPane)
);
