import {
  GET_OPERATORS_SUCCESS,
  USER_LOGOUT_SUCCESS,
  CREATE_OPERATOR_SUCCESS,
  UPDATE_OPERATOR_SUCCESS,
  DELETE_OPERATOR_SUCCESS,
  GET_DRIVERS_SUCCESS
} from "../types";

const initialState = {
  agents: [],
  drivers: []
};

export function operatorReducer(state = initialState, action) {
  switch (action.type) {
    case GET_OPERATORS_SUCCESS: {
      const { agents = [] } = action;
      return { ...state, agents };
    }
    case CREATE_OPERATOR_SUCCESS: {
      const { newOperator } = action;
      return { ...state, agents: [newOperator, ...state.agents] };
    }
    case GET_DRIVERS_SUCCESS: {
      const { drivers } = action;
      return { ...state, drivers };
    }
    case UPDATE_OPERATOR_SUCCESS: {
      const { edited } = action;
      let { agents } = state;
      const index = agents.findIndex(agent => agent._id === edited._id);
      agents = [...agents.slice(0, index), edited, ...agents.slice(index + 1)];
      return { ...state, agents };
    }
    case DELETE_OPERATOR_SUCCESS: {
      const { _id } = action;
      let { agents } = state;
      agents = agents.filter(agent => agent._id !== _id);
      return { ...state, agents };
    }
    case USER_LOGOUT_SUCCESS: {
      const state = initialState;
      return state;
    }
    default:
      return state;
  }
}
