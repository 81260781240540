export const GET_OPERATORS = "GET_OPERATORS";
export const GET_OPERATORS_SUCCESS = "GET_OPERATORS_SUCCESS";
export const GET_OPERATORS_FAILED = "GET_OPERATORS_FAILED";
export const AGENT = "agent";
export const ADMIN = "admin";
export const ONLINE = "online";
export const OFFLINE = "offline";
export const AWAY = "away";
export const BUSY = "busy";
export const CREATE_OPERATOR = "CREATE_OPERATOR";
export const CREATE_OPERATOR_SUCCESS = "CREATE_OPERATOR_SUCCESS";
export const CREATE_OPERATOR_FAILED = "CREATE_OPERATOR_FAILED";
export const UPDATE_OPERATOR = "UPDATE_OPERATOR";
export const UPDATE_OPERATOR_SUCCESS = "UPDATE_OPERATOR_SUCCESS";
export const UPDATE_OPERATOR_FAILED = "UPDATE_OPERATOR_FAILED";
export const DELETE_OPERATOR = "DELETE_OPERATOR";
export const DELETE_OPERATOR_SUCCESS = "DELETE_OPERATOR_SUCCESS";
export const DELETE_OPERATOR_FAILED = "DELETE_OPERATOR_FAILED";

export const GET_DRIVERS = "GET_DRIVERS";
export const GET_DRIVERS_SUCCESS = "GET_DRIVERS_SUCCESS";
export const GET_DRIVERS_FAILURE = "GET_DRIVERS_FAILURE";
