import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  getOperators,
  getOperator,
  deleteOperator
} from "../../../store/actions";
import { MyDialog } from "../departments/list";
import { CommonHeader, UpdateIconButton } from "../../helpers/commonButtons";
class OperatorsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false
    };
  }

  componentDidMount() {
    this.props.getOperators();
  }

  openModal = id => e => {
    this.setState({ openDialog: true, deleteId: id });
  };

  closeModal = () => {
    this.setState({ openDialog: false });
  };

  deleteOperator = () => {
    this.props.deleteOperator(this.state.deleteId);
    this.closeModal();
  };

  goToCreate = () => {
    this.props.history.push({
      pathname: "/dashboard/operators/create",
      state: { modal: true, heading: "New Operator" }
    });
  };

  editOperator = id => async () => {
    this.props.history.push({
      pathname: `/dashboard/operators/${id}/edit`,
      state: { modal: true, heading: "Edit Operator" }
    });
  };

  render() {
    const { agents = [] } = this.props;
    return (
      <div style={{ padding: 15 }}>
        <CommonHeader header="List of Operators" onClick={this.goToCreate} />
        {agents.length > 0 && (
          <Paper>
            <Table className="w-100">
              <TableHead style={{ backgroundColor: "#d3eefd" }}>
                <TableRow>
                  <TableCell
                    className="w-50 places  text-center"
                    style={{ fontSize: 15, fontFamily: "OpenSans-SemiBold" }}
                  >
                    Agent Name
                  </TableCell>
                  <TableCell
                    className="w-50 places text-center"
                    style={{ fontSize: 15, fontFamily: "OpenSans-SemiBold" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {agents.map((agent, i) => (
                  <TableRow key={i}>
                    <TableCell
                      className="text-center"
                      style={{ fontSize: 15, fontFamily: "OpenSans-SemiBold" }}
                    >
                      {agent.name}
                    </TableCell>
                    <TableCell className="text-center">
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <UpdateIconButton
                          onClick={this.editOperator(agent._id)}
                          image="/images/edit_icon.png"
                          color="#e7f6f3"
                        />
                        <UpdateIconButton
                          onClick={this.openModal(agent._id)}
                          image="/images/delete_icon.png"
                          color="#feecec"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )}
        <MyDialog
          open={this.state.openDialog}
          closeModal={this.closeModal}
          submitModal={this.deleteOperator}
          title="Delete Operator"
          content="Are you sure want to delete this operator?"
          submitButton="Yes"
          cancelButton="No"
        />
      </div>
    );
  }
}

export default connect(
  ({ operatorReducer }) => {
    const { agents = [] } = operatorReducer;
    return { agents };
  },
  { getOperators, getOperator, deleteOperator }
)(OperatorsList);
