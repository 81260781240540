export const TODAY = "TODAY";
export const YESTERDAY = "YESTERDAY";
export const THIS_WEEK = "THIS_WEEK";
export const LAST_WEEK = "LAST_WEEK";
export const THIS_MONTH = "THIS_MONTH";
export const LAST_MONTH = "LAST_MONTH";
export const RANGE = "RANGE";
export const GET_REPORTS_DATA = "GET_REPORTS_DATA";
export const GET_REPORTS_DATA_SUCCESS = "GET_REPORTS_DATA_SUCCESS";
export const GET_REPORTS_DATA_FAILED = "GET_REPORTS_DATA_FAILED";
