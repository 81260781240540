import { fade } from "@material-ui/core/styles/colorManipulator";

export const styles = theme => ({
  showcards: {
    display: "flex",
    height: 120,
    width: "100%"
  },
  card: {
    width: "25%",
    marginRight: 8,
    marginLeft: 8,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center"
  },
  search: {
    position: "relative",

    borderRadius: theme.shape.borderRadius,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 3,
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  navbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 15,
    marginTop: 15,
    marginRight: theme.spacing.unit * 2
  },
  inputRoot: {
    color: "black",
    width: "100%"
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },

  heading: {
    fontSize: 15,
    marginLeft: 8,
    fontWeight: 700
  },
  radiobutton: {
    fontSize: 12,
    marginLeft: 10,
    fontWeight: 500
  },
  main: {
    color: "White",
    fontSize: 15,
    fontFamily: "OpenSans-SemiBold",
    marginBottom: 15,
    padding: 0
  },
  sub: {
    fontSize: 18,
    fontFamily: "OpenSans-Bold",
    color: "white",
    padding: 0
  },
  row: {
    padding: 0,
    "&:nth-of-type(odd)": {
      backgroundColor: "#d8edf9"
    }
  },
  tabularform: {
    height: 510,
    width: "100%",
    display: "flex",
    marginTop: 20,
    marginBottom: 20
  },
  tabularcard: {
    width: "50%",
    marginLeft: 8,
    marginRight: 8,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10
    // paddingBottom:0
  },
  padding: {
    padding: 0
  },
  tableheading: {
    fontSize: 14,
    fontFamily: "OpenSans-SemiBold"
  },
  tablesubheading: {
    fontSize: 12,
    color: "gray",
    fontFamily: "OpenSans-Regular",
    paddingBottom: 10,
    paddingTop: 10
  }
});
