import React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const InputText = function(props) {
  const { classes, children, ...otherProps } = props;
  return (
    <div display="flex">
      <TextField className={classes.textField} {...otherProps}>
        {children}
      </TextField>
    </div>
  );
};

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    backgroundColor: "white",
    display: "flex",
    flexGrow: 1
  }
});
InputText.propTypes = {
  classes: PropTypes.object.isRequired
};

export const MyInputText = withStyles(styles)(InputText);
